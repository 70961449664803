import { SvgIcon, IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import * as S from './style';
import useTheme from '../../../hooks/useTheme';

interface IProps {
  text: string;
  cancelIcon?: boolean;
  removeChipHandler?: () => void;
  chipKey?: string | number;
  iconName?: IconNamesEnum;
  shouldAddTooltip?: boolean;
  tooltipText?: string;
  isHiddenListShown?: boolean;
}

const Chip: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const {
    text,
    cancelIcon,
    removeChipHandler,
    chipKey,
    iconName,
    shouldAddTooltip,
    isHiddenListShown,
    tooltipText
  } = props;
  const handleRemoveChip = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeChipHandler();
  };

  return (
    <S.Chip chipKey={chipKey} isHiddenListShown={isHiddenListShown}>
      {iconName && (
        <S.LeftIconWrapper>
          <SvgIcon
            name={iconName}
            fill="blue2"
            width="1.5rem"
            height="1.5rem"
            data-testid="chip-left-icon"
          />
        </S.LeftIconWrapper>
      )}
      {shouldAddTooltip ? (
        <S.Tooltip
          tooltipContent={tooltipText || text}
          textColor={theme.colors.blue2}
          wrapperId="chipTooltip"
        >
          <S.Text>{text}</S.Text>
        </S.Tooltip>
      ) : (
        <S.Text>{text}</S.Text>
      )}
      {cancelIcon && (
        <S.CancelIcon>
          <SvgIcon
            name={IconNamesEnum.CANCEL}
            onClick={handleRemoveChip}
            fill="lightBlue4"
            width="1.3rem"
            height="1.3rem"
            data-testid={chipKey ? `cancel-chip-icon-${chipKey}` : 'cancel-chip-icon'}
          />
        </S.CancelIcon>
      )}
    </S.Chip>
  );
};

export default Chip;
