import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './Routes.style';
import { routes } from '../utils/constants/routes';
import { isLoggedInSelector } from '../store/auth/auth.selectors';
import ActivateInvitationCallback from './Login/ResetPassword/ActivateInvitationCallback';
import AuthRoutes from './AuthRoutes';
import SentryErrorBoundary from '../services/sentry/components/SentryErrorBoundary';
import MissingEmailSocialLogin from './Login/MissingEmailSocialLogin/MissingEmailSocialLogin';
import NewCustomer from './NewCustomer/NewCustomer';
import NewLogin from './NewLogin/NewLogin';
import { parametersQueries } from '../queryClient/parameters/parameters.queries';

const Main = lazy(() => import('./Main/Main'));

const SocialLoginMigration = lazy(
  () => import('./Login/SocialLoginMigration/SocialLoginMigration')
);

const SamlLoginMigration = lazy(() => import('./Login/SamlLoginMigration/SamlLoginMigration'));
const FreeTrial = lazy(() => import('./Marketplace/FreeTrial/FreeTrial'));
const AccountMapping = lazy(() => import('./Marketplace/AccountMapping/AccountMapping'));

interface RoutesProps {
  hideNavigation?: boolean;
}

const Routes = ({ hideNavigation }: RoutesProps) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { systemParams } = parametersQueries.useSystemParameters();

  return (
    <Suspense fallback={<S.Loader dataTestId="loader-routes-suspense" />}>
      <Switch>
        <Route exact path={routes.missingEmailSocialLogin} component={MissingEmailSocialLogin} />
        <Route exact path={routes.socialConfirmMigration} component={SocialLoginMigration} />
        <Route exact path={routes.samlConfirmMigration} component={SamlLoginMigration} />
        <Route exact path={routes.activateInvitation} component={ActivateInvitationCallback} />
        {isLoggedIn && (
          <Route exact path={routes.marketplaceAccountMapping} component={AccountMapping} />
        )}
        {isLoggedIn && <Route exact path={routes.marketplaceFreeTrial} component={FreeTrial} />}
        {isLoggedIn && <Route path={routes.newCustomer.root} component={NewCustomer} />}

        {isLoggedIn ? (
          <Main hideNavigation={hideNavigation} />
        ) : (
          <SentryErrorBoundary errorArea="authentication">
            {systemParams.external_feature_flag_new_login_enabled ? <NewLogin /> : <AuthRoutes />}
          </SentryErrorBoundary>
        )}
      </Switch>
    </Suspense>
  );
};

export default Routes;
