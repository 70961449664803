import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../components/Notification/Notification';
import { i18translation } from '../../../locale/translations/i18NsPaths';

const useSamlRedirectNotification = () => {
  const { t } = useTranslation();
  const samlRedirectAndNotification = window.sessionStorage.getItem('samlRedirectAndNotification');

  useEffect(() => {
    if (samlRedirectAndNotification) {
      window.sessionStorage.removeItem('samlRedirectAndNotification');
      window.sessionStorage.setItem('redirectToSamlConfig', 'true');

      if (samlRedirectAndNotification === 'success') {
        showNotification(
          {
            title: t(i18translation.accessManagement.tabs.singleSignOn.notifications.successTitle),
            body: t(
              i18translation.accessManagement.tabs.singleSignOn.notifications.testConnectionSuccess
            )
          },
          'success'
        );
      }

      if (samlRedirectAndNotification === 'failed') {
        showNotification(
          {
            title: t(i18translation.accessManagement.tabs.singleSignOn.notifications.failedTitle),
            body: t(
              i18translation.accessManagement.tabs.singleSignOn.notifications.testConnectionFailed
            )
          },
          'error'
        );
      }
    }
  }, [samlRedirectAndNotification]);
};

export default useSamlRedirectNotification;
