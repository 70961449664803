import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Input } from '@redislabsdev/redis-ui-components';
import { i18translation } from '../../../../../../locale/translations/i18NsPaths';
import { getLoginFormSchema } from '../../../../utils/LoginHelper';
import { login } from '../../../../../../store/auth/auth.action';
import { routes } from '../../../../../../utils/constants/routes';
import * as S from './SignInWithEmailForm.style';

const SignInWithEmailForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;
  const LoginFormSchema = getLoginFormSchema(t);

  const onSubmit = ({ email, password }: { email: string; password: string }) => {
    window.sessionStorage.setItem('loginType', 'direct');

    dispatch(login({ username: email, password, callCsrfFlag: true }));
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginFormSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ handleSubmit, isValid, handleChange, errors, touched, handleBlur }) => (
        <S.SignInWithEmailFormContainer>
          <Input
            autoComplete="email"
            name="email"
            type="email"
            id="textfield--email"
            data-testid="textinput--email-address"
            label={t(keyPrefix.email)}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
          />
          <Input
            autoComplete="password"
            name="password"
            type="password"
            label={t(keyPrefix.password)}
            id="textfield--password"
            data-testid="textinput--password"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && errors.password}
          />
          <S.ButtonContainer>
            <Link data-testid="link--forgot-password" to={routes.forgotPassword}>
              {t(keyPrefix.forgotPassword)}
            </Link>
            <Button data-testid="button--submit" disabled={!isValid} type="submit">
              {t(keyPrefix.login)}
            </Button>
          </S.ButtonContainer>
        </S.SignInWithEmailFormContainer>
      )}
    </Formik>
  );
};

export default SignInWithEmailForm;
