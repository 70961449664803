import { FC } from 'react';
import { UseComboboxStateChange } from 'downshift';
import { DropdownMenu } from '@redislabsdev/redislabs-ui-components';
import * as S from './InputFields.style';
import { setDataScrollAnchor } from '../../../utils/dataScrollAnchorUtils';

interface IProps {
  width: string;
  name?: string;
  label: string;
  error?: string;
  states: State[];
  selectedState: State;
  onStateSelect: (state: State) => void;
  info?: React.ReactNode;
  disabled?: boolean;
}

const StateSelectInput: FC<IProps> = ({
  name,
  label,
  width,
  states,
  onStateSelect,
  selectedState,
  error,
  info = null,
  disabled = false
}) => {
  const onOptionSelected = ({ selectedItem }: UseComboboxStateChange<State>) => {
    onStateSelect(selectedItem);
  };

  const filterStates = (items: State[], input: string) =>
    items.filter((state) => state?.name?.toLocaleLowerCase().includes(input.toLowerCase()));

  return (
    <S.InputFieldContainer width={width} {...(name && setDataScrollAnchor(name))}>
      <S.Label data-testid="label--province-name">
        {label}
        {info}
      </S.Label>
      <DropdownMenu
        disabled={disabled}
        isSearchable
        items={states}
        error={error}
        onSelectedItemChange={onOptionSelected}
        dataTestIdSuffix="province-name"
        selectedItem={selectedState}
        filterFunction={filterStates}
        placeholder=""
        renderItem={(item: State) => item.name}
        errorTooltipProps={{ zIndex: 6 }}
      />
    </S.InputFieldContainer>
  );
};

export default StateSelectInput;
