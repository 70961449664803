import { UseComboboxStateChange } from 'downshift';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { newRedisOptInUtils } from 'utils/newRedisOptIn/newRedisOptIn.utils';
import { useLocation } from 'react-router-dom';
import * as S from './CreationRegionSelect.style';
import { CreationRegionSelectProps } from './CreationRegionSelect.types';
import NewRedisOptInToggle from './components/NewRedisOptInToggle/NewRedisOptInToggle';
import RenderRegionItem from './components/RenderRegionItem/RenderRegionItem';
import { typeGuard } from '../../../../../../utils/typeGuard';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';
import { accountIdSelector } from '../../../../../../store/auth/auth.selectors';
import getIsRequestMoreRegionsVisible from './utils/getIsRequestMoreRegionsVisible';
import useGetRegionUrl from './hooks/useGetRegionUrl';

const CreationRegionSelect = ({
  selectedRegion,
  onRegionSelect,
  isSupportingNewRedisOptIn,
  onNewRedisOptInClick,
  withMoreRegionsPageLink,
  isOptedToNewRedis,
  redisOptInVersion,
  newRedisOptInRegionIds,
  allRegions,
  dropdownProps,
  withTitle = true
}: CreationRegionSelectProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const keyPrefix = i18translation.createSubscription.cloudVendor;
  const { systemParams } = parametersQueries.useSystemParameters();
  const readMoreLinkUrl = useGetRegionUrl();

  const newRedisOptInRegionIdsArray = newRedisOptInUtils.parseRegionIds(newRedisOptInRegionIds);
  const isOptInToNewRedisDisabled = !allRegions.some((region) =>
    newRedisOptInRegionIdsArray.includes(Number(region.id))
  );

  const handleRegionSelect = (option: UseComboboxStateChange<CloudRegion>) => {
    if (!option.selectedItem.region_id) {
      return;
    }

    onRegionSelect(option.selectedItem);
  };

  const accountId = useSelector(accountIdSelector);

  const isRequestMoreRegionsVisible = getIsRequestMoreRegionsVisible({
    withMoreRegionsPageLink,
    systemParams,
    currentAccountId: Number(accountId),
    isOptedToNewRedis,
    pathname
  });

  const regionsItems = isRequestMoreRegionsVisible
    ? [...allRegions, { moreRegionsPageLink: true }]
    : allRegions;

  const handleRegionFilter = (items: typeof regionsItems, searchValue: string) => {
    if (!searchValue) return items;

    return items.filter((item) => {
      const isCloudRegion = typeGuard<CloudRegion>('region_id');

      if (isCloudRegion(item)) {
        return [item.name, item.city_name, item.country_name]
          .join(' ')
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }

      return true;
    });
  };

  const renderRegionItem = (region: CloudRegion) => {
    const isCloudRegion = typeGuard<CloudRegion>('region_id');

    return isCloudRegion(region) ? (
      <RenderRegionItem
        region={region}
        isSupportingNewRedisOptIn={isSupportingNewRedisOptIn}
        newRedisOptInRegionIds={newRedisOptInRegionIds}
        redisOptInVersion={redisOptInVersion}
      />
    ) : (
      <S.MoreRegionsPageLink readMoreLink={readMoreLinkUrl}>
        {t(keyPrefix.moreRegionsPageLinkText)}
      </S.MoreRegionsPageLink>
    );
  };

  return (
    <S.RegionSelectContainer data-testid="single-region-select">
      <S.HeaderContainer>
        {withTitle && <S.Title>{t(keyPrefix.selectRegion)}</S.Title>}
        {isSupportingNewRedisOptIn && (
          <NewRedisOptInToggle
            onNewRedisOptInClick={onNewRedisOptInClick}
            isOptedToNewRedis={isOptedToNewRedis}
            redisOptInVersion={redisOptInVersion}
            isOptInToNewRedisDisabled={isOptInToNewRedisDisabled}
          />
        )}
      </S.HeaderContainer>
      <S.DropdownMenu
        items={regionsItems}
        renderItem={renderRegionItem}
        selectedItem={selectedRegion}
        onSelectedItemChange={handleRegionSelect}
        dataTestIdSuffix="region"
        toggleButtonId="selection-area-button--region-select"
        filterFunction={handleRegionFilter}
        isSearchable
        isRequestMoreRegionsVisible={isRequestMoreRegionsVisible}
        {...dropdownProps}
      />
    </S.RegionSelectContainer>
  );
};

export default CreationRegionSelect;
