import { Input } from '@redislabsdev/redislabs-ui-components';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { ApiPaths } from 'services/api/apiPaths';
import { TextButton } from '@redislabsdev/redis-ui-components';
import { mfaPayloadSelector } from '../../../../../../store/auth/auth.selectors';
import MfaModalWrapper from '../MfaModalWrapper/MfaModalWrapper';
import * as CommonStyles from '../../MfaLogin.styles';
import RedisApiAxiosInstance from '../../../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { extractErrorAndShowToast } from '../../../../../../utils/helpers/extractErrorAndShowToast';
import * as S from './SmsLoginModal.style';

interface Props {
  switchToAuthAppModal: () => void;
}

const SmsLoginModal = ({ switchToAuthAppModal }: Props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.mfaLogin.smsAuthenticationModal;
  const { phoneNumber, totpFactorAvailable, username, password } = useSelector(mfaPayloadSelector);

  const AuthAppModalBtn = (
    <TextButton onClick={switchToAuthAppModal}>{t(keyPrefix.useAuthApp)}</TextButton>
  );

  const reSendMfaCode = async () => {
    let authName = username?.toLowerCase();
    try {
      if (authState.isAuthenticated) {
        const user = await oktaAuth.getUser();
        authName = user?.email;
      }
      await RedisApiAxiosInstance.post(`${ApiPaths.LOGIN}/resend_mfa_code`, {
        username: authName,
        password
      });
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  return (
    <MfaModalWrapper
      title={t(keyPrefix.title)}
      mfaType="SMS"
      bottomLeftComponent={totpFactorAvailable && AuthAppModalBtn}
    >
      {(onChange, value) => {
        return (
          <>
            <CommonStyles.InputHint htmlFor="sms-code">
              {t(keyPrefix.enterCode, { phoneNumber })}
            </CommonStyles.InputHint>
            <Input
              data-testid="sms-code"
              id="sms-code"
              name="sms-code"
              onChange={onChange}
              value={value}
              width="100%"
            />
            <S.ReSendSmsContainer>
              <S.DidNotReceive>{t(keyPrefix.didntReceive)}</S.DidNotReceive>
              <TextButton data-testid="button--sms-code-again" onClick={reSendMfaCode}>
                {t(keyPrefix.sendCodeAgain)}
              </TextButton>
            </S.ReSendSmsContainer>
          </>
        );
      }}
    </MfaModalWrapper>
  );
};

export default SmsLoginModal;
