export const DATA_SCROLL_ANCHOR = 'data-scrollanchor';

export const setDataScrollAnchor = (fieldName: string) => ({
  [DATA_SCROLL_ANCHOR]: fieldName
});

export const getElementByDataScroll = (anchor: string): HTMLElement => {
  return (
    document.getElementsByName(anchor)[0] ||
    document.querySelector(`[${DATA_SCROLL_ANCHOR}="${anchor}"]`)
  );
};
