import { useTranslation } from 'react-i18next';
import * as S from './LoginRelatedComponents.style';
import emailPatternBg from '../../assets/icons/EmailPatternBg.svg';
import signInUpEmail from '../../assets/icons/SignInUpEmail.svg';
import usePrefixedTranslation from '../../hooks/usePrefixedTranslation';
import RedisLogo from './RedisLogo';
import UserFormInfo from '../../screens/NewLogin/components/UserFormInfo/UserFormInfo';
import { parametersQueries } from '../../queryClient/parameters/parameters.queries';
import { i18translation } from '../../locale/translations/i18NsPaths';

const ThanksForSignUp = () => {
  const { prefixedT } = usePrefixedTranslation('LoginScreen.thanksForSignUp');
  const { systemParams } = parametersQueries.useSystemParameters();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;

  return (
    <>
      {systemParams.external_feature_flag_new_login_enabled ? (
        <UserFormInfo
          title={t(keyPrefix.thanksForSignUp.thanksTitle)}
          subTitle={t(keyPrefix.thanksForSignUp.checkEmail)}
          text={t(keyPrefix.thanksForSignUp.checkEmailActivationMessage)}
          showBackToLoginButton={false}
        />
      ) : (
        <S.ThanksForSignUpContainer>
          <img src={emailPatternBg} alt="background with starts" />

          <RedisLogo />

          <S.ThanksForSignUpTitle>{prefixedT('thanksTitle')}</S.ThanksForSignUpTitle>

          <img src={signInUpEmail} alt={prefixedT('signInUpEmail')} />

          <S.CheckEmailText>{prefixedT('checkEmail')}</S.CheckEmailText>

          <S.ClickMessage>{prefixedT('checkEmailMessage1')}</S.ClickMessage>
          <S.ClickMessage>{prefixedT('checkEmailMessage2')}</S.ClickMessage>
        </S.ThanksForSignUpContainer>
      )}
    </>
  );
};

export default ThanksForSignUp;
