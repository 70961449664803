import { OriginsType } from './config.types';

export const ServiceOrigins: { [key: string]: OriginsType } = {
  LOCAL: {
    SM: 'https://test.redislabs.com:3000/local_api/v1',
    MW: 'https://test.redislabs.com:3000/maintenance-window',
    KYC: 'https://test.redislabs.com:3000/kyc/api',
    TGW: 'https://test.redislabs.com:3000/cloud-transit-gateway'
  },

  TEST: {
    SM: 'http://localhost',
    MW: 'http://localhost',
    KYC: 'http://localhost',
    TGW: 'http://localhost'
  }
} as const;
