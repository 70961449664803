import { useTranslation } from 'react-i18next';
import { Button } from '@redislabsdev/redis-ui-components';
import useMobileBanner from './hooks/useMobileBanner';
import redisLogo from '../../../../assets/icons/RedisBlackLogo.svg';
import illustration from '../../../../assets/illustrations/MobileBannerIllustration.svg';
import { i18translation } from '../../../../locale/translations/i18NsPaths';
import * as S from './MobileBanner.style';

const MobileBanner = () => {
  const { showBanner, dismissBanner } = useMobileBanner();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.mobileBanner;

  if (!showBanner) {
    return null;
  }

  return (
    <S.BannerContainer data-testid="login--mobile-banner">
      <S.LogoContainer>
        <img src={redisLogo} alt="Redis" />
      </S.LogoContainer>
      <img src={illustration} alt="Illustration" />
      <S.TextsContainer>
        <S.Heading size="L">{t(keyPrefix.heading)}</S.Heading>
        <S.Body size="XL">{t(keyPrefix.body)}</S.Body>
        <Button
          variant="secondary-ghost"
          onClick={dismissBanner}
          size="large"
          data-testid="login--mobile-banner--button"
        >
          {t(keyPrefix.continueButton)}
        </Button>
      </S.TextsContainer>
    </S.BannerContainer>
  );
};

export default MobileBanner;
