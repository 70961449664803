import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './NewRedisOptInTooltipContent.style';
import { getDataTestId } from '../../../../../../../../../../utils/dataAttributesHelpers';
import {
  REDIS_72_PREVIEW_LINK,
  REDIS_7_PREVIEW_LINK
} from '../../../../../../../../../../utils/constants/docLinks';
import { RedisOptInVersion } from '../../../../CreationRegionSelect.types';

interface Props {
  redisOptInVersion: RedisOptInVersion;
}

export const NewRedisOptInTooltipContent = ({ redisOptInVersion }: Props) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newRedisOptIn;

  let readMoreLink = REDIS_7_PREVIEW_LINK;
  if (redisOptInVersion === '7.2') {
    readMoreLink = REDIS_72_PREVIEW_LINK;
  }

  return (
    <div>
      <S.Title {...getDataTestId('readonlylabel', 'redis-7-explanation-title')}>
        {t(keyPrefix.optInTooltipTitle, { version: redisOptInVersion })}
      </S.Title>
      <p {...getDataTestId('readonlyvalue', 'redis-7-explanation')}>
        {t(keyPrefix.optInInfoDescription, { version: redisOptInVersion })}
      </p>
      <S.ReadMore readMoreLink={readMoreLink} />
    </div>
  );
};
