import { UseQueryOptions } from '@tanstack/react-query';
import { onboardingApi } from '../../services/api/resources/onboarding/onboarding.resource';
import useSmQuery from '../useSmQuery';
import onboardingKeys from './onboarding.keys';

const useOnboardingQuery = (queryOptions: UseQueryOptions<OnboardingGetOptions> = {}) => {
  const queryFn = async () => {
    const { data } = await onboardingApi.getOptions();

    return data;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: onboardingKeys.getOptions,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

export const onboardingQueries = {
  useOnboarding: useOnboardingQuery
};
