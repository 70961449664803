import { useMemo, useState } from 'react';

import { SvgIcon, IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import * as S from './MultiSelectDropDownList.style';
import { MultiSelectDropDownProps } from '../MultiSelectDropDown.types';

interface MultiSelectDropDownListProps
  extends Pick<
    MultiSelectDropDownProps,
    | 'options'
    | 'toggleSelected'
    | 'disableSearch'
    | 'selectAll'
    | 'withCheckbox'
    | 'renderItem'
    | 'highlightSelectedRow'
    | 'scrolledListMaxHeight'
  > {
  searchPlaceholder?: string;
}

const MultiSelectDropDownList: React.FC<MultiSelectDropDownListProps> = ({
  options,
  toggleSelected,
  disableSearch,
  searchPlaceholder,
  selectAll,
  withCheckbox,
  highlightSelectedRow,
  renderItem = (item) => `${item.labelPrefix || ''} ${item.label}`,
  scrolledListMaxHeight = '13rem'
}) => {
  const [searchValue, setSearchValue] = useState('');
  const handleChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const handleItemPress = (chosenId: string) => {
    toggleSelected(chosenId);
    if (searchValue.length) {
      setSearchValue('');
    }
  };

  const filteredOptions = useMemo(() => {
    if (searchValue.trim()) {
      return options.filter(({ label, labelPrefix, searchKeywords }) =>
        `${label?.toLowerCase()} ${labelPrefix?.toLowerCase()} ${searchKeywords
          ?.join(' ')
          .toLowerCase()}`.includes(searchValue?.toLowerCase())
      );
    }

    return options;
  }, [searchValue, options]);

  return (
    <S.Container data-testid="multi-select-list-container">
      {!disableSearch && (
        <S.InputContainer data-testid="multi-select-search-container">
          <SvgIcon name={IconNamesEnum.SEARCH} fill="gray1" width={15} height={15} />

          <S.SearchInput
            type="text"
            value={searchValue}
            placeholder={searchPlaceholder}
            onChange={handleChange}
            data-testid="multi-select-search-input"
          />
        </S.InputContainer>
      )}
      <S.ScrolledContent
        $scrolledListMaxHeight={scrolledListMaxHeight}
        data-testid="multi-select-list"
      >
        {filteredOptions.map(
          (
            {
              id,
              selected,
              disabled = false,
              withCheckbox: withCheckboxInnerProp = withCheckbox,
              showBorder = false
            },
            idx
          ) => (
            <S.ListItemContainer
              key={id}
              onClick={() => handleItemPress(id)}
              selected={selected}
              disabled={disabled}
              highlightSelectedRow={highlightSelectedRow}
              showBorder={showBorder}
              aria-selected={selected}
              data-testid={`drop-down-item-${idx + 1}`}
            >
              <S.ListItem>
                {withCheckboxInnerProp && (
                  <input
                    type="checkbox"
                    onChange={() => {}}
                    checked={selected}
                    disabled={disabled}
                  />
                )}
                <S.ItemText>{renderItem(filteredOptions[idx])}</S.ItemText>
              </S.ListItem>
            </S.ListItemContainer>
          )
        )}
      </S.ScrolledContent>
      {selectAll && (
        <S.SelectAllContainer>
          <S.SelectAllText onClick={selectAll.onClick}>{selectAll.selectAllText}</S.SelectAllText>
        </S.SelectAllContainer>
      )}
    </S.Container>
  );
};

export default MultiSelectDropDownList;
