import { first } from 'lodash';
import intersection from 'lodash/intersection';
import { CloudProviders } from 'components/CloudProvider/CloudProvider.types';
import { sortRegions } from 'screens/CreateSubscription/SubscriptionTypes/Essential/utils/sortRegions';
import { EssentialPlan } from 'store/createSubscription/essentials/essentials.types';
import { getAllowedPlanBackups } from 'utils';
import { getBackupDestinationsOptions } from 'screens/SubscriptionsAndDatabases/components/DbFormComponents/RemoteBackup/utils/backupDestinationsOptions';
import { randomString } from 'screens/SubscriptionsAndDatabases/Subscriptions/utils/randomString';
import {
  dataEvictionPolicyItems,
  noEvictionItem
} from 'screens/SubscriptionsAndDatabases/components/ViewDB/Configuration/utils/dropdowns';
import { defaultValues } from 'screens/SubscriptionsAndDatabases/components/ViewDB/Configuration/utils/defaultValues';
import { SASL_USERNAME_SUFFIX_LENGTH } from 'screens/SubscriptionsAndDatabases/components/ViewDB/Configuration/helpers/buildForm/utils/constants';
import { getDbAlertsFromPlanAlerts } from 'screens/SubscriptionsAndDatabases/components/DbFormComponents/Alerts/Alerts.utils';
import { DEFAULT_ITEM_SIZE } from 'utils/constants/dbProperties';
import { VendorRegionMap, RegionNamePlan, CreateFreeDatabaseParams } from '../FreeDb.types';

interface GetFreePlansProps {
  plans: EssentialPlan[];
  isOptedToNewRedis?: boolean;
  newRedisRegions?: number[];
}
export const getFreePlans = ({
  plans = [],
  isOptedToNewRedis,
  newRedisRegions
}: GetFreePlansProps) => {
  return plans.filter((plan) => {
    if (plan.plan_type === 'free' && plan.plan_tab === 'Standard') {
      if (isOptedToNewRedis && !newRedisRegions?.includes(plan.region_id)) {
        return false;
      }

      return true;
    }

    return false;
  });
};

export const DEFAULT_VENDOR: CloudProviders = 'aws';

export const getVendorRegionPlanMapper = (freePlans: EssentialPlan[]) => {
  const vendorRegionPlanMapper = {} as VendorRegionMap;

  for (const freePlan of freePlans) {
    const planProvider: CloudProviders = freePlan.cloud.toLocaleLowerCase() as CloudProviders;
    if (!vendorRegionPlanMapper[planProvider]) {
      vendorRegionPlanMapper[planProvider] = {};
    }
    vendorRegionPlanMapper[planProvider][freePlan.region] = freePlan.id;
  }

  return vendorRegionPlanMapper;
};

export const getFirstRegion = (
  regionNamesByVendor: RegionNamePlan,
  getRegionByName: (regionName: string) => CloudRegion
) => {
  return first(
    sortRegions(
      Object.keys(regionNamesByVendor || {}).map((regionName) => getRegionByName(regionName))
    )
  )?.name;
};

export const buildFreeDatabaseBody = ({
  cluster,
  modules,
  selectedSubscription,
  plan,
  isRedisStackSupported
}: CreateFreeDatabaseParams): Partial<Bdb> => {
  const { isBackupRedislabsAllowed } = getAllowedPlanBackups(plan);
  const backupDestinations = getBackupDestinationsOptions(isBackupRedislabsAllowed);
  const selectedBackupStorageType = backupDestinations[0];
  const securityCredentials = randomString();
  let dataEvictionPolicy = dataEvictionPolicyItems.find((item) =>
    plan.bdb_type === 'redis' ? item.redisDefault : item.memcachedDefault
  )?.value;

  if (isRedisStackSupported) {
    const hasEvictionCapability = intersection(
      ...modules.map((module) => module.module_capabilities)
    ).includes('eviction');

    if (!hasEvictionCapability) {
      dataEvictionPolicy = noEvictionItem.value;
    }
  }

  const accessControlAdditionalValues = defaultValues.access_control;
  const accessControl = {
    ...accessControlAdditionalValues,
    default_user: true,
    source_ips: [],
    has_ssl_auth: false,
    public_key: null,
    enforce_client_authentication: false,
    has_source_ips: false,
    ...(plan.bdb_type === 'memcached'
      ? {
          sasl_user_name: `mc-${randomString(SASL_USERNAME_SUFFIX_LENGTH)}`,
          sasl_user_password: securityCredentials,
          has_sasl_auth: true,
          has_redis_password: false,
          redis_password: null
        }
      : {
          redis_password: securityCredentials,
          has_redis_password: !!securityCredentials
        })
  };
  const values = {
    ...defaultValues,
    backup: false,
    backup_path: null,
    backup_interval: null,
    backup_interval_offset: null,
    is_redislabs_backup_path: selectedBackupStorageType?.id === 'defaultBackupRepo',
    bdb_type: plan.bdb_type,
    is_redis_stack: isRedisStackSupported,
    bdb_alerts: getDbAlertsFromPlanAlerts(plan.plan_alerts, true),
    access_control: accessControl,
    eviction_policy: dataEvictionPolicy,
    data_persistence: 'disabled' as DataPersistence,
    cluster,
    subscription: selectedSubscription.id,
    replication: false,
    has_sharding: false,
    size: 0,
    replica_of: [],
    regex_rules: [],
    sharding_type: 'default-regex-rules' as ShardingType,
    oss_cluster: false,
    bdb_modules: [],
    oss_cluster_api_preferred_ip_type: 'internal' as OSSClusterPolicy,
    sharding_policy: 'shards_count' as ShardingPolicy,
    max_throughput: 0,
    shards_count: null,
    average_value_size: DEFAULT_ITEM_SIZE,
    sync: false,
    sync_state: 'no-sync' as SyncState,
    is_rof: plan?.is_rof,
    is_auto_created: true
  };

  return values;
};
