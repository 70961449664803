import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

const mfaAxiosOptions = {
  headers: {
    'Content-Type': 'text/plain'
  }
};

export const enableMfa = () => RedisApiAxiosInstance.post(`${ApiPaths.MFA}/enable_mfa`);
export const disableMfa = () => RedisApiAxiosInstance.post(`${ApiPaths.MFA}/disable_mfa`);
export const mfaInfo = () => RedisApiAxiosInstance.get(`${ApiPaths.MFA}/get_mfa_info`);
export const mfaSmsSetup = (data: string) =>
  RedisApiAxiosInstance.post(`${ApiPaths.MFA}/config_sms`, data, mfaAxiosOptions);
export const mfaSmsVerify = (data: string) =>
  RedisApiAxiosInstance.post(`${ApiPaths.MFA}/activate_factor/SMS`, data, mfaAxiosOptions);
export const mfaTotpVerify = (data: string) =>
  RedisApiAxiosInstance.post(`${ApiPaths.MFA}/activate_factor/Totp`, data, mfaAxiosOptions);
export const mfaTotpDisable = () =>
  RedisApiAxiosInstance.post(`${ApiPaths.MFA}/remove_factor/Totp`);
