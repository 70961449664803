/* eslint-disable @typescript-eslint/dot-notation */
import { OriginsType } from './config.types';
import { ServiceOrigins } from './serviceOrigins';

const PROD_HOST = 'app.redislabs.com';
const RC_URL = 'https://sm.ui.dev.redislabs.com/rc';
const dynamicHost = /^([\w\-.]+).redislabs.com$/.exec(window.location.host);
let search;
try {
  search = decodeURIComponent(window.location.search);
} catch {
  search = window.location.search;
}
const searchParams = new URLSearchParams(search);
let API_ENV: string;

if (window.location.host === PROD_HOST) {
  API_ENV = 'app';
} else if (process.env.VITE_APP_API_ENV) {
  API_ENV = process.env.VITE_APP_API_ENV;
} else if (window.location.href.startsWith(RC_URL)) {
  API_ENV = 'app-qa.qa';
} else if (searchParams.get('api_env')) {
  API_ENV = searchParams.get('api_env');
  window.sessionStorage.setItem('api_env', API_ENV);
} else if (window.sessionStorage.getItem('api_env') !== null) {
  API_ENV = window.sessionStorage.getItem('api_env');
} else if (dynamicHost?.[1]) {
  API_ENV = dynamicHost[1]; // eslint-disable-line prefer-destructuring
} else {
  API_ENV = 'app-qa.qa';
}

ServiceOrigins.DEFAULT = {
  SM: `https://${API_ENV}.redislabs.com/api/v1`,
  MW: `https://${API_ENV}.redislabs.com/api/v1/maintenance-window`,
  KYC: `https://${API_ENV}.redislabs.com/kyc/api`,
  TGW: `https://${API_ENV}.redislabs.com/api/v1/cloud-transit-gateway`
};

let CURRENT_ORIGINS: OriginsType;
if (process.env.NODE_ENV === 'test') {
  API_ENV = 'TEST';
  CURRENT_ORIGINS = ServiceOrigins.TEST;
} else if (API_ENV === 'LOCAL') {
  CURRENT_ORIGINS = ServiceOrigins.LOCAL;
} else {
  CURRENT_ORIGINS = ServiceOrigins.DEFAULT;
}

// Allow overriding the default origins with environment variables
CURRENT_ORIGINS = {
  SM: process.env.VITE_APP_SM_URL || CURRENT_ORIGINS.SM,
  MW: process.env.VITE_APP_MW_URL || CURRENT_ORIGINS.MW,
  KYC: process.env.VITE_APP_KYC_URL || CURRENT_ORIGINS.KYC,
  TGW: process.env.VITE_APP_TGW_URL || CURRENT_ORIGINS.TGW
};

export const Config = {
  API_ENV,
  CURRENT_ORIGINS,
  RELEASE_NUMBER: process.env.VITE_APP_RELEASE_NUMBER || 'local'
};

window['MFE_MODULES'] = {
  kyc:
    process.env.VITE_APP_LOCAL_KYC_MFE === 'true'
      ? 'https://test.redislabs.com:5002/assets/entry.js'
      : `https://${API_ENV}.redislabs.com/cloud-ui-modules/kyc/entry.js`
};
