import { ToggleButton } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { newTheme } from '@redislabsdev/redis-ui-styles';
import { Badge } from '@redislabsdev/redis-ui-components';
import * as S from './NewRedisOptInToggle.style';
import { NewRedisOptInProps } from '../../CreationRegionSelect.types';
import { getDataTestId } from '../../../../../../../../utils';
import HoverableInfoIcon from '../../../../../../../../components/HoverableInfoIcon/HoverableInfoIcon';
import { NewRedisOptInTooltipContent } from './components/NewRedisOptInTooltipContent/NewRedisOptInTooltipContent';

interface NewRedisOptInToggleProps
  extends Required<
    Pick<NewRedisOptInProps, 'onNewRedisOptInClick' | 'isOptedToNewRedis' | 'redisOptInVersion'>
  > {
  isOptInToNewRedisDisabled: boolean;
}

const NewRedisOptInToggle = ({
  onNewRedisOptInClick,
  isOptedToNewRedis,
  redisOptInVersion,
  isOptInToNewRedisDisabled
}: NewRedisOptInToggleProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newRedisOptIn;
  const handleRedis7OptInClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNewRedisOptInClick(event.target.checked);
  };

  return (
    <S.Container>
      <ToggleButton
        disabled={isOptInToNewRedisDisabled}
        onChange={handleRedis7OptInClick}
        checked={isOptedToNewRedis}
        {...getDataTestId('switch', 'redis-7-opt-in')}
      />
      <S.ToggleLabel
        {...getDataTestId('readonlylabel', 'redis-7-opt-in')}
        isDisabled={isOptInToNewRedisDisabled}
      >
        {t(keyPrefix.optInToggleLabel, {
          version: redisOptInVersion
        })}
      </S.ToggleLabel>
      <HoverableInfoIcon
        size="L"
        data-testid="bla"
        iconColor={newTheme.semantic.color.text.neutral600}
        tooltipProps={{
          delayHide: 200,
          maxWidth: '36rem',
          tooltipContent: <NewRedisOptInTooltipContent redisOptInVersion={redisOptInVersion} />
        }}
      />
      {redisOptInVersion === '7.2' && <Badge label={t(keyPrefix.newBadge)} variant="notice" />}
    </S.Container>
  );
};

export default NewRedisOptInToggle;
