export default {
  titles: {
    redisEnterpriseCloud: 'Redis Enterprise Cloud',
    database: 'Database'
  },
  sizes: {
    small: 'small',
    large: 'large'
  },
  indicatorStatus: {
    active: 'active',
    warning: 'warning',
    error: 'error',
    pending: 'pending',
    active_change_pending: 'active-change-pending',
    provision_done: 'provision_done',
    destroy_pending: 'destroy_pending',
    provision_pending: 'provision_pending',
    plan_queued: 'plan_queued',
    update_queued: 'update_queued',
    plan_pending: 'plan_pending',
    destroy_error: 'destroy_error',
    provision_error: 'provision_error',
    validate_provision_error: 'validate_provision_error',
    validate_provision_queued: 'validate_provision_queued',
    update_error: 'update_error',
    validate_provision_done: 'validate_provision_done',
    validate_provision_pending: 'validate_provision_pending',
    update_done: 'update_done',
    plan_error: 'plan_error'
  },
  alertType: {
    datasetSize: 'dataset_size',
    multipleDatasetSize: 'datasets_size',
    throughputHigherThan: 'throughput_higer_than',
    throughputLowerThan: 'throughput_lower_than',
    latency: 'latency',
    backupDelayed: 'backup_delayed',
    syncerUnableToSync: 'syncer_unable_to_sync',
    highSyncerLag: 'high_syncer_lag',
    alertsTooltip: 'alerts_tooltip',
    connectionsLimit: 'connections_limit',
    syncsourceError: 'syncsource_error',
    syncsourceLag: 'syncsource_lag'
  },
  subscriptionType: {
    pro: 'pro',
    essentials: 'essentials'
  },
  cloudServicesCompanyName: {
    google: 'Google',
    amazon: 'Amazon',
    azure: 'Azure'
  },
  cloudServicesCompanyAbbreviation: {
    google: 'gcp',
    amazon: 'aws',
    azure: 'azure'
  },
  subCardProperties: {
    regions: 'regions',
    storageType: 'storageType',
    memoryUsage: 'memoryUsage',
    subscriptionID: 'subscriptionID',
    numOfDatabases: 'numOfDatabases',
    indicatorStatus: 'indicatorStatus',
    memoryTotalSize: 'memoryTotalSize',
    subscriptionName: 'subscriptionName',
    cloudServicesCompanyName: 'cloudServicesCompanyName'
  },
  bytesConversionNumbers: {
    convertMegaBytesToGigaBytesNum: 1024,
    roundUpToIntegerIfGreater: 100
  },
  byteLabels: {
    GB: 'GB',
    MB: 'MB'
  },
  hashingPolicies: {
    custom: 'custom-regex-rules',
    standard: 'default-regex-rules'
  },
  subscriptionURLString: 'subscription',
  databaseType: {
    redis: 'redis',
    memcached: 'memcached'
  },
  redisACLErrorType: {
    specific: 'specific',
    general: 'general'
  },
  initialCategoryTitle: 'Subscription ID',
  minimumScreenWidth: 1280,
  averageDbOptionBadgeSize: 25,
  numOfAlwaysDisplayedDatabaseModules: 4,
  lowestDbVersionToSupportAcl: 6,
  apicallStatus: {
    idle: 'idle',
    pending: 'pending',
    rejected: 'rejected',
    resolved: 'resolved'
  }
} as const;
