import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { OktaAuth } from '@okta/okta-auth-js';
import { EmailRegex } from '../../../utils/validations/formRegexes';
import { i18translation } from '../../../locale/translations/i18NsPaths';

export const getLoginFormSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(EmailRegex, t(i18translation.LoginScreen.validations.emailRegex))
      .required(t(i18translation.LoginScreen.validations.emailRequired)),
    password: Yup.string().required(t(i18translation.LoginScreen.validations.passwordRequired))
  });

export const handleSocialLogin = async (idp: string, oktaAuth: OktaAuth, loginType?: string) => {
  loginType && setLoginType(loginType);

  await oktaAuth.signInWithRedirect({ idp });
};

export const setLoginType = (loginType: string) => {
  window.sessionStorage.setItem('loginType', loginType);
};

export const emailValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(EmailRegex, t(i18translation.LoginScreen.forgotPassScreen.validations.emailRegex))
      .required(t(i18translation.LoginScreen.forgotPassScreen.validations.emailRequired))
  });
