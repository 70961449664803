import { Typography } from '@redislabsdev/redis-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './FreeDbBanner.style';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';

const FreeDbBanner = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newCustomer.freeDB;
  const { systemParams } = parametersQueries.useSystemParameters();

  return (
    <S.Banner>
      <S.BannerRow>
        <S.BannerLeftCol>
          <S.BannerRow>
            <S.CheckIcon size="M" />
            <Typography.Body size="M">{t(keyPrefix.row1)}</Typography.Body>
          </S.BannerRow>
          <S.BannerRow>
            <S.CheckIcon size="M" />
            <Typography.Body size="M">{t(keyPrefix.row2)}</Typography.Body>
          </S.BannerRow>
        </S.BannerLeftCol>
        <S.BannerRightCol>
          <S.BannerRow>
            <S.CheckIcon size="M" />
            <Typography.Body size="M">{t(keyPrefix.row3)}</Typography.Body>
          </S.BannerRow>
          <S.BannerRow>
            <S.CheckIcon size="M" />
            <Typography.Body size="M">{t(keyPrefix.row4)}</Typography.Body>
          </S.BannerRow>
        </S.BannerRightCol>
      </S.BannerRow>
      {systemParams.is_redis_stack_supported && (
        <>
          <S.BannerRow>
            <S.BannerLine />
          </S.BannerRow>
          <S.BannerRow>
            <Typography.Body size="M">{t(keyPrefix.row5col1)}</Typography.Body>
            <S.StackIcon />
            <Typography.Body size="M">{t(keyPrefix.row5col2)}</Typography.Body>
            <S.Spacer />
            <Typography.Body size="M">{t(keyPrefix.row5col3)}</Typography.Body>
          </S.BannerRow>
        </>
      )}
    </S.Banner>
  );
};

export default FreeDbBanner;
