import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';
import { newTheme } from '@redislabsdev/redis-ui-styles';

export const LoginFromInvitationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignInTitle = styled(Typography.Heading)`
  color: ${newTheme.semantic.color.text.neutral800};
`;

export const SetPasswordFormTitle = styled(Typography.Body)`
  color: ${newTheme.semantic.color.text.neutral800};
  margin-bottom: 2.4rem;
  font-size: 1.8rem;
`;
