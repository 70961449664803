import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { AccessToken, IDToken, EVENT_REMOVED } from '@okta/okta-auth-js';
import {
  setOktaAuthorizationHeader,
  setOktaIdTokenHeader
} from '../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { login } from '../../../store/auth/auth.action';

export const useSetOktaAuthorizationHeaders = () => {
  const dispatch = useDispatch();
  const {
    oktaAuth: { tokenManager }
  } = useOktaAuth();

  const setExistingTokens = (accessToken: AccessToken, idToken: IDToken) => {
    if (accessToken) {
      setOktaAuthorizationHeader(accessToken);
    }
    if (idToken) {
      setOktaIdTokenHeader(idToken);
    }
  };

  const addToken = (token: AccessToken, idToken: IDToken) => {
    setOktaAuthorizationHeader(token);
    setOktaIdTokenHeader(idToken);
    dispatch(login({}));

    tokenManager.off(EVENT_REMOVED);
    tokenManager.on(EVENT_REMOVED, (key) => {
      // TO DO: Make a generic Okta Header Setters
      if (key === 'accessToken') {
        setOktaAuthorizationHeader(null);
        setOktaIdTokenHeader(null);
      }
    });
  };

  return { addToken, setExistingTokens };
};
