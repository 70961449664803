import * as S from '../Login.style';
import LoginLogoSide from '../LoginLogoSide';
import SignUpFormWrapper from './components/SignUpForm/SignUpFormWrapper';
import MobileBanner from '../MobileBanner/MobileBanner';
import { parametersQueries } from '../../../queryClient/parameters/parameters.queries';

const SignUp = () => {
  const { systemParams } = parametersQueries.useSystemParameters();

  const SignUpForm = () => {
    return (
      <>
        <SignUpFormWrapper />
        <MobileBanner />
      </>
    );
  };

  return (
    <>
      {systemParams.external_feature_flag_new_login_enabled ? (
        <SignUpForm />
      ) : (
        <S.LoginPageContainer>
          <LoginLogoSide />
          <SignUpForm />
        </S.LoginPageContainer>
      )}
    </>
  );
};

export default SignUp;
