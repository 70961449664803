import { ApiPaths } from 'services/api/apiPaths';
import { createResourceApi } from '../../createResourceApi';

export const onboardingApi = createResourceApi(ApiPaths.ONBOARDING, (rest) => ({
  getOptions: () => rest.get<OnboardingGetOptions>({}),

  postOptions: (data: OnboardingPostOptions) =>
    rest.post<void>({
      data
    })
}));
