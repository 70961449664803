import { useRef, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useEffectAfterMount = (cb: () => void, deps: any[]) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      cb();
    }
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectAfterMount;
