import { useMemo } from 'react';
import { IconNamesEnum, SvgIcon, Tooltip } from '@redislabsdev/redislabs-ui-components';
import * as S from '../DropDowns/sharedStyles';
import ChipList from '../ChipList';
import { ChipItem } from '../../../types/interfaces';
import { MultiSelectDropDownProps } from './MultiSelectDropDown.types';
import MultiSelectDropDownList from './MultiSelectDropDownList/MultiSelectDropDownList';
import useDetectClickOutside from '../../../hooks/useDetectClickOutside';
import useEffectAfterMount from '../../../hooks/useEffectAfterMount';

const MultiSelectDropDown: React.FC<MultiSelectDropDownProps> = ({
  width,
  label,
  toggleSelected,
  options,
  disableSearch,
  clearAllSelected,
  selectAll,
  disabled = false,
  testId,
  withCheckbox,
  renderItem,
  highlightSelectedRow = false,
  scrolledListMaxHeight,
  shouldAllowDeletion = true,
  onDropdownToggle,
  error,
  className
}) => {
  const { isVisible, toggleIsVisible, wrapperRef } = useDetectClickOutside();

  const selectedItems = useMemo(() => {
    const selectedItemsFromOptions = options.filter((option) => option?.selected);
    const convertedSelectedOptions: ChipItem[] = selectedItemsFromOptions.map(
      ({ label: selectedItemLabel, labelPrefix, Icon, id }) => ({
        key: id,
        label: `${labelPrefix ? `${labelPrefix} ` : ''}${selectedItemLabel}`,
        onDelete: () => toggleSelected(id),
        Icon
      })
    );

    return convertedSelectedOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffectAfterMount(() => {
    onDropdownToggle?.(isVisible);
  }, [isVisible, onDropdownToggle]);

  return (
    <S.MainContainer ref={wrapperRef} data-testid={testId} {...{ width }} className={className}>
      <S.HeaderContainer
        $disabled={disabled}
        $isError={!isVisible && !!error}
        $isVisible={isVisible}
        onClick={toggleIsVisible}
        data-testid="multi-tree-select-header"
      >
        <S.FlexRow>
          {selectedItems.length ? (
            <S.FlexRow>
              <ChipList itemList={selectedItems} editable={shouldAllowDeletion} showTooltip />
              {clearAllSelected && shouldAllowDeletion && (
                <S.ClearAllIcon onClick={clearAllSelected} />
              )}
            </S.FlexRow>
          ) : (
            <S.Label>{label}</S.Label>
          )}
          <S.RightSection>
            <Tooltip tooltipContent={!isVisible && error} displayOnContent>
              {error && !isVisible && (
                <SvgIcon
                  width="2rem"
                  height="2rem"
                  data-testid="error-icon"
                  name={IconNamesEnum.ERROR2}
                />
              )}
            </Tooltip>
            <S.ToggleHeaderIcon $isVisible={isVisible} />
          </S.RightSection>
        </S.FlexRow>
      </S.HeaderContainer>
      {isVisible && (
        <MultiSelectDropDownList
          {...{
            toggleSelected,
            options,
            disableSearch,
            selectAll,
            renderItem,
            withCheckbox,
            highlightSelectedRow,
            scrolledListMaxHeight
          }}
        />
      )}
    </S.MainContainer>
  );
};

export default MultiSelectDropDown;
