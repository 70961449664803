import { isInteger } from 'lodash';
import { EssentialPlan } from '../../store/createSubscription/essentials/essentials.types';

const parseRegionIds = (newRedisRegionIds: string | number) => {
  if (typeof newRedisRegionIds !== 'string') {
    if (isInteger(newRedisRegionIds)) {
      return [newRedisRegionIds];
    }

    return [];
  }

  const result = newRedisRegionIds
    .replace(/\s+/g, '')
    .split(',')
    .filter((str) => str)
    .map(Number);

  if (!result || result?.some((regionId) => !Number.isInteger(regionId))) {
    return [];
  }

  return result;
};

const getOnlyNewRedisPlans = (plans: EssentialPlan[], newRedisRegionIds: string) =>
  plans.filter((plan) => {
    return parseRegionIds(newRedisRegionIds).includes(plan.region_id);
  });

const getIsSupportingNewRedis = (newRedisRegionIds: string | number) =>
  parseRegionIds(newRedisRegionIds).length > 0;

export const newRedisOptInUtils = {
  getOnlyNewRedisPlans,
  parseRegionIds,
  getIsSupportingNewRedis
};
