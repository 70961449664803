import { Tooltip as TooltipComponent } from '@redislabsdev/redislabs-ui-components';
import styled from 'styled-components/macro';

interface IChip {
  chipKey: string | number;
  isHiddenListShown: boolean;
}

export const Chip = styled.span.attrs(({ chipKey }: IChip) => ({
  'data-testid': `chip-${chipKey}`
}))<IChip>`
  background-color: ${({ theme: { colors } }) => colors.lightBlue4};
  border-radius: 4px;
  height: 2.8rem;
  padding: 0.5rem 0.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ isHiddenListShown }) => (!isHiddenListShown ? '100%' : 'calc(100% - 4rem)')};
  #chipTooltip {
    overflow: hidden;
  }

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.blue9};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

export const CancelIcon = styled.div`
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme: { colors } }) => colors.blue9};
  border-radius: 50%;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftIconWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
`;

export const Tooltip = styled(TooltipComponent)`
  width: 100%;
`;
