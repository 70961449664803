import { DropDownItemsType, TemplateSelectSelectedKeys } from '../TemplateSelect.types';

export const getDropDownItemsFromTemplate = (template: OnboardingGetOptions): DropDownItemsType => {
  const items: DropDownItemsType = {};

  Object.keys(template?.options || {}).forEach((key) => {
    items[key] = {
      type: template.options[key].type,
      label: template.options[key].readableKey,
      items: template.options[key].options.map((item, index) => ({
        id: String(index),
        label: item.readable,
        selected: false,
        value: item.value
      }))
    };
  });

  return items;
};

export const getSelectedKeys = (dropDownItems: DropDownItemsType): TemplateSelectSelectedKeys => {
  const selectedKeys: TemplateSelectSelectedKeys = {};

  Object.keys(dropDownItems).forEach((key) => {
    const options = dropDownItems[key].items
      .filter((item) => item.selected)
      .map((item) => item.value);

    if (dropDownItems[key].type === 'multi-select') {
      selectedKeys[key] = options.length > 0 ? options : null;
    }

    if (dropDownItems[key].type === 'single-select') {
      selectedKeys[key] = options[0] || null;
    }
  });

  return selectedKeys;
};
