import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';
import { newTheme } from '@redislabsdev/redis-ui-styles';

export const SignInFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignInTitle = styled(Typography.Heading)`
  color: ${newTheme.semantic.color.text.neutral800};
`;

export const SignupContainer = styled(Typography.Body)`
  white-space: pre-wrap;
  text-align: center;
  & a {
    text-decoration: underline;
  }
`;
