import { newTheme } from '@redislabsdev/redis-ui-styles';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ToggleLabel = styled.span<{ isDisabled: boolean }>`
  color: ${({ isDisabled }) =>
    isDisabled ? newTheme.semantic.color.text.neutral500 : newTheme.semantic.color.text.neutral700};
`;
