import { newTheme } from '@redislabsdev/redis-ui-styles';
import { DropdownMenu } from '@redislabsdev/redislabs-ui-components';
import { DropdownMenuStyles } from '@redislabsdev/redislabs-ui-components/ui/components/DropdownMenu';
import { HeaderDropdownButton } from '@redislabsdev/redislabs-ui-components/ui/components/DropdownMenu/components/HeaderButton/HeaderButton.style';
import { ItemsLengthText } from 'components/HiddenListNumber/HiddenListNumber.style';
import { Chip, Text } from 'components/Shared/Chip/style';
import { HiddenListNumberWrapper } from 'components/Shared/ChipList/style';
import { HeaderContainer } from 'components/Shared/DropDowns/sharedStyles';
import MultiSelectDropDown from 'components/Shared/MultiSelectDropDown/MultiSelectDropDown';
import {
  Container as MultiSelectContainer,
  ListItem,
  ScrolledContent
} from 'components/Shared/MultiSelectDropDown/MultiSelectDropDownList/MultiSelectDropDownList.style';

import styled from 'styled-components/macro';

export const Container = styled.div`
  color: ${newTheme.semantic.color.text.neutral800};
  line-height: 5.6rem;
  font-size: 2.4rem;
  white-space: pre-wrap;
`;

export const DropDownItem = styled.div`
  font-size: 1.4rem;
  color: ${newTheme.semantic.color.text.neutral700};
`;

export const DropDownSingleSelection = styled(DropdownMenu)`
  display: inline-block;

  ${HeaderDropdownButton} {
    gap: 0.4rem;
    padding: 0.4rem;
    height: 3.1rem;
    border-bottom: 1px solid ${newTheme.semantic.color.text.neutral700};
    min-width: 1rem;

    ${DropDownItem} {
      color: ${newTheme.semantic.color.text.neutral600};
      font-size: 2.4rem;
    }

    & :hover {
      background-color: transparent;
    }
  }

  ${DropdownMenuStyles.ListContainer} {
    width: 23rem;
    line-height: 2.3rem;
    padding: 0;

    & ul {
      max-height: 16rem;
    }
  }
`;

export const DropDownMultiSelection = styled(MultiSelectDropDown)`
  display: inline-block;
  min-width: 5rem;
  max-width: 20rem;

  ${HeaderContainer} {
    display: inline-flex;
    border: none;
    border-bottom: 1px solid ${newTheme.semantic.color.text.neutral700};
    border-radius: 0;
    padding: 0;
    height: 3.1rem;

    ${Chip} {
      background-color: transparent;
      color: ${newTheme.semantic.color.text.primary600};
      padding: 0.4rem;

      ${Text} {
        color: ${newTheme.semantic.color.text.neutral600};
      }
    }

    ${HiddenListNumberWrapper} {
      align-items: center;
      background-color: transparent;
    }

    ${ItemsLengthText} {
      color: ${newTheme.semantic.color.text.primary600};
    }
  }

  ${ScrolledContent} {
    max-height: 16rem;
  }

  ${MultiSelectContainer} {
    width: 23rem;
    line-height: 2.3rem;
    overflow: hidden;

    ${ListItem} {
      transition: none;

      & :hover {
        background-color: ${newTheme.semantic.color.text.neutral400};
        color: ${newTheme.semantic.color.text.neutral700};
      }

      & input {
        flex-shrink: 0;
      }

      & label {
        margin-inline-end: 0;
      }
    }
  }
`;
