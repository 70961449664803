import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import SetNewPasswordForm from '../SetNewPasswordForm/SetNewPasswordForm';
import * as S from './ResetPassword.style';
import UserFormInfo from '../UserFormInfo/UserFormInfo';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import { usersMutations } from '../../../../queryClient/users/users.mutations';
import { okNoContentStatus } from '../../../../utils/constants/api/statuses';
import { ExtendedAxiosError } from '../../../../types/interfaces';
import { ResetPasswordFormValues } from '../../../Login/ResetPassword/ResetPassword.types';
import { useLoginContext } from '../../Login.context';
import { usersQueries } from '../../../../queryClient/users/users.queries';

const ResetPassword = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.resetPasswordScreen;
  const [resetSuccess, setResetSuccess] = useState(false);
  const { mutate: setNewPasswordMutate } = usersMutations.useSetNewPassword();
  const searchParams = new URLSearchParams(search);
  const [isResetPasswordLinkExpired, setIsResetPasswordLinkExpired] = useState(false);
  const showLoaderContext = useLoginContext();

  useEffect(() => {
    showLoaderContext.setShowLoader(true);
  }, []);

  usersQueries.useResetPasswordCallback(search, {
    onError: (err) => {
      setIsResetPasswordLinkExpired(true);
    },
    onSettled: () => {
      showLoaderContext.setShowLoader(false);
    }
  });

  const onSubmit = async (formValues: ResetPasswordFormValues) => {
    const userId = searchParams.get('a');
    setNewPasswordMutate(
      { id: userId, password: formValues.password },
      {
        onSuccess: (response) => {
          if (response?.status === okNoContentStatus) {
            setResetSuccess(true);
          }
        },
        onError: (e: ExtendedAxiosError) => {
          extractErrorAndShowToast(e);
        }
      }
    );
  };

  if (isResetPasswordLinkExpired) {
    return <UserFormInfo title={t(keyPrefix.passResetLinkExpired)} />;
  }

  return (
    <S.ResetPasswordContainer>
      {resetSuccess ? (
        <UserFormInfo title={t(keyPrefix.passResetSuccess)} />
      ) : (
        <>
          <S.ResetPasswordFormTitle>{t(keyPrefix.resetPassword)}</S.ResetPasswordFormTitle>
          <SetNewPasswordForm handleSubmit={onSubmit} />
        </>
      )}
    </S.ResetPasswordContainer>
  );
};

export default ResetPassword;
