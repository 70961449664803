import { useState } from 'react';
import MobileBanner from './components/MobileBanner/MobileBanner';
import * as S from './NewLogin.style';
import LoginAuthRoutes from './LoginAuthRoute';
import LoginBackground from './components/LoginBackground/LoginBackground';
import { LoginLoaderContext } from './Login.context';

const NewLogin = () => {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <LoginBackground>
      <LoginLoaderContext.Provider value={{ showLoader, setShowLoader }}>
        {showLoader && (
          <S.LoginLoaderWrapper dataTestId="loader-login-screen">
            <S.Loader />
          </S.LoginLoaderWrapper>
        )}
        <S.FormContainer>
          <LoginAuthRoutes />
        </S.FormContainer>
      </LoginLoaderContext.Provider>
      <MobileBanner />
    </LoginBackground>
  );
};

export default NewLogin;
