import styled, { css } from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';

export const Container = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: 4px;
  margin-top: 0.3rem;
  background: white;
  z-index: 3;
  position: absolute;
  color: ${({ theme: { colors } }) => colors.blue1};
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.4rem;
  flex: 1;
  margin: 1.4rem 1.4rem 0;
  padding: 0.2rem;
  border: ${({ theme: { colors } }) => `1px solid ${colors.gray1}`};
  border-radius: 4px;

  & > ${SvgIcon} {
    margin-left: 1rem;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  color: inherit;
  font-size: 1.4rem;
  border: none;
  font-family: inherit;
  text-indent: 1rem;

  ::-webkit-input-placeholder {
    color: ${({ theme: { colors } }) => colors.gray1};
  }
`;

export const ScrolledContent = styled.ul<{ $scrolledListMaxHeight: string }>`
  padding: 1rem 0;
  overflow: auto;
  max-height: ${({ $scrolledListMaxHeight }) => $scrolledListMaxHeight};
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.4rem 1.6rem;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
  & > * {
    margin-inline-end: 5px;
  }

  input[type='checkbox'] {
    margin-right: 1rem;
  }
`;

export const ListItemContainer = styled.div<{
  selected: boolean;
  highlightSelectedRow: boolean;
  disabled: boolean;
  showBorder: boolean;
}>(
  ({ theme, selected, highlightSelectedRow, disabled, showBorder }) => css`
    ${selected &&
    highlightSelectedRow &&
    css`
      background-color: ${theme.colors.lightGray1};
      ${ItemText} {
        color: ${theme.colors.gray1};
      }
    `};

    ${ListItem}:hover {
      background-color: ${({ theme: { colors } }) => colors.lightBlue2};
    }

    ${showBorder &&
    css`
      & ${ListItem} {
        border-top: 1px solid ${() => theme.colors.lightGray2};
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
      color: ${theme.colors.gray1};
    `}
  `
);

export const ItemText = styled.label`
  font-size: 1.4rem;
  width: 100%;
`;

export const SelectAllContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray1};
  padding: 0.4rem 1.4rem;
`;

export const SelectAllText = styled.span(
  ({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.blue6};
    font-size: 1.4rem;
  `
);
