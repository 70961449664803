import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { mfaPayloadSelector } from '../../../../../store/auth/auth.selectors';
import { MfaTypes } from '../../../../../store/auth/auth.types';
import { chooseFactor } from '../utils/mfaLogin.calles';

const useFactorChange = (mfaType: MfaTypes) => {
  const { authState, oktaAuth } = useOktaAuth();

  const { username, totpFactorAvailable } = useSelector(mfaPayloadSelector);

  const getUsernameAndChooseFactor = async () => {
    let authName = username?.toLowerCase();

    if (authState.isAuthenticated) {
      const user = await oktaAuth.getUser();
      authName = user?.email;
    }

    chooseFactor(authName, mfaType);
  };

  const { status, mutate } = useMutation(['choose_factor', mfaType], getUsernameAndChooseFactor);

  useEffect(() => {
    // TODO: remove authState check when #RED-90489 is done
    if (authState !== null && totpFactorAvailable) {
      mutate();
    }
  }, [mutate, totpFactorAvailable, authState]);

  return status;
};

export default useFactorChange;
