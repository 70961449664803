import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const InputHint = styled(Typography.Body)`
  margin-bottom: 1.2rem;
  margin-top: 3rem;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
  flex: 1;
`;
