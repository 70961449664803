import styled, { css, DefaultTheme } from 'styled-components/macro';
import { SvgIcon, IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { MenuDirection } from '../../../hooks/useMenuDirection';
import { rem } from '../../../utils/style/responsive.style';

export const HEADER_HEIGHT = 36;
const MAX_MENU_HEIGHT = 178;
export const HEADER_MENU_SPACE = 5;

type NodeKey = string | number;

interface IIconContainerProps {
  depth: number;
  $nodeKey: NodeKey;
}
export const IconContainer = styled.span.attrs(({ $nodeKey }: IIconContainerProps) => ({
  'data-testid': `expand-item-icon-container-${$nodeKey}`
}))<IIconContainerProps>`
  margin-left: ${({ depth }) => `${rem(depth * 15)}rem`};
`;

interface IArrowIconProps {
  $isOpen: boolean;
  $hasChildren: boolean;
  $nodeKey: NodeKey;
}

export const ArrowIcon = styled(SvgIcon).attrs(({ $nodeKey }: IArrowIconProps) => ({
  width: 7,
  height: 7,
  name: IconNamesEnum.SORT_BY,
  fill: 'darkBlue1',
  'data-testid': `expand-item-icon-${$nodeKey}`
}))<IArrowIconProps>`
  transform: ${({ $isOpen }) => !$isOpen && 'rotate(-90deg)'};
  opacity: ${({ $hasChildren }) => ($hasChildren ? 1 : 0)};
  cursor: pointer;
`;

export const MainContainer = styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
`;

export const Label = styled.span`
  padding: 4px;
`;

export const ToggleHeaderIcon = styled(SvgIcon).attrs(() => ({
  width: 13,
  height: 17,
  name: IconNamesEnum.ARROW_DOWN,
  fill: 'darkBlue1'
}))<{ $isVisible: boolean }>`
  transform: rotate(${({ $isVisible }) => ($isVisible ? 180 : 0)}deg);
  transition: 0.3s;
  cursor: pointer;
`;

interface IHeaderContainerProps {
  theme: DefaultTheme;
  $isVisible: boolean;
  $disabled?: boolean;
  $isError?: boolean;
}

export const HeaderContainer = styled.div<IHeaderContainerProps>`
  ${({ theme: { colors }, $isVisible, $disabled, $isError }) => css`
    width: 100%;
    height: ${rem(HEADER_HEIGHT)}rem;
    border: 1px solid ${colors.gray1};
    border-radius: 4px;
    padding: 4px 8px 4px 4px;
    color: ${colors.blue1};
    transition: border-color 0.3s ease-in;
    cursor: pointer;
    ${$disabled &&
    css`
      background-color: ${colors.blue13};
      pointer-events: none;
      color: ${colors.gray1};
      ${ToggleHeaderIcon} {
        path {
          fill: ${colors.gray1};
        }
      }
    `}
    &:hover,
    &:focus {
      border-color: ${colors.blue10};
    }

    ${() => {
      if ($isVisible) {
        return css`
          border-color: ${colors.blue10};
        `;
      }
      if ($isError) {
        return css`
          border-color: ${colors.red1};
          &:hover,
          &:focus {
            border-color: ${colors.red1};
          }
        `;
      }

      return '';
    }}
  `};
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;

  & > * {
    margin-inline-end: 5px;
  }
`;

export const ListItemContainer = styled.div`
  ${ListItem}:hover {
    background-color: ${({ theme: { colors } }) => colors.lightGray2};
  }
`;

export const List = styled.ul<{
  menuWidth: number;
  menuHeight: number;
  isVisible: boolean;
  containerYCoor: number;
  menuDirection: MenuDirection;
}>`
  width: ${({ menuWidth }) => `${menuWidth}px`};
  box-shadow: ${({ theme: { shadows } }) => shadows.shadow2};
  border-radius: 4px;
  position: fixed;
  max-height: ${rem(MAX_MENU_HEIGHT)}rem;
  padding: 1rem 0;
  background: white;
  overflow: auto;
  overflow-x: hidden;
  z-index: 4;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  ${ListItem} {
    padding: 0 1rem;
  }
  ${({ containerYCoor, menuDirection, menuHeight }) =>
    // eslint-disable-next-line no-nested-ternary
    containerYCoor && menuHeight
      ? menuDirection === 'bottom'
        ? css`
            top: calc(${HEADER_HEIGHT}px + ${HEADER_MENU_SPACE}px + ${containerYCoor}px);
          `
        : css`
            top: calc(${containerYCoor}px - ${menuHeight}px - ${HEADER_MENU_SPACE}px);
          `
      : null};
`;

export const ClearAllIcon = styled(SvgIcon).attrs({
  width: 16,
  height: 16,
  name: IconNamesEnum.FAILED2,
  fill: 'gray1',
  'data-testid': 'clear-all-icon'
})`
  margin-inline-end: 5px;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
`;
