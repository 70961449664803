import { showToast } from '@redislabsdev/redislabs-ui-components/ui/components/Toast';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getQueryParam } from '../../../utils/getQueryParam/getQueryParam';
import { extractError } from '../../../utils/helpers/extractError';
import { i18translation } from '../../../locale/translations/i18NsPaths';

const useAwsMarketPlaceQueryParamsMessage = () => {
  const { t } = useTranslation();
  const errorParam = getQueryParam('error');
  const infoParam = getQueryParam('info');
  const accountParam = getQueryParam('account');
  const accountsParam = getQueryParam('accounts');

  useEffect(() => {
    if (errorParam) {
      const error = extractError({ errors: { code: errorParam } });
      showToast(t(error.message));
    } else if (infoParam === 'marketplace-account-bound-correctly') {
      if (accountParam) {
        showToast(
          t(i18translation.awsMarketPlace.messages.accountBoundCurrently, {
            account: accountParam
          }),
          'success'
        );
      } else if (accountsParam) {
        showToast(
          t(i18translation.awsMarketPlace.messages.accountsBoundCurrently, {
            accounts: accountsParam
          }),
          'success'
        );
      }
    }
  }, [errorParam, infoParam, accountParam, t]);
};

export default useAwsMarketPlaceQueryParamsMessage;
