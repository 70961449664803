import styled, { css } from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';

const commonContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const titleCss = css`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 2.8rem;
  font-weight: 700;
`;

export const WelcomeText = styled.div<{ marginBottom?: string }>`
  ${titleCss};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
`;

export const ThanksForSignUpContainer = styled.div`
  ${commonContainer};

  & > img:first-of-type {
    position: absolute;
    transform: scale(1.3);
  }

  & button {
    z-index: 1;
  }
`;

export const RedisLogoContainer = styled(SvgIcon)`
  position: absolute;
  top: 3.33rem;
  left: 3rem;
  width: 19rem;
  height: 5.5rem;
`;

export const ImgContainer = styled.div`
  margin-bottom: 4.37rem;
`;

export const ThanksForSignUpTitle = styled.div`
  ${titleCss};
  margin-bottom: 7.8rem;
`;

export const CheckEmailText = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 2.2rem;
  font-weight: 700;
  margin-top: 3.6rem;
  margin-bottom: 1.4rem;
`;

export const ClickMessage = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 1.4rem;
  margin: 0.6rem;
`;
