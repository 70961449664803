import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';
import { newTheme } from '@redislabsdev/redis-ui-styles';

export const SocialLoginButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SocialLoginImage = styled.div`
  margin-bottom: 0.3rem;
`;

export const SocialLoginName = styled(Typography.Body)`
  color: ${newTheme.semantic.color.text.neutral800};
`;
