import styled from 'styled-components/macro';

export const HoverText = styled.div`
  text-align: left;
  max-height: 49vh;
`;

export const ItemsLengthText = styled.span`
  line-height: 0.2rem;
`;
