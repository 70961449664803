import styled from 'styled-components/macro';
import { newTheme } from '@redislabsdev/redis-ui-styles';
import { Loader as LoaderComponent } from '@redislabsdev/redislabs-ui-components';
import imageLogo from '../assets/logo.svg';
import imageBg from './assets/bg.svg';

export const Container = styled.div`
  position: absolute;
  z-index: 6;

  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f2f3fc;
  background-image: url(${imageBg});
  background-size: cover;
  background-position: 0% 50%;
`;

export const ImageLogo = styled.div`
  width: 10.3rem;
  height: 3.5rem;
  position: absolute;
  top: 4rem;
  left: 4.7rem;

  background-image: url(${imageLogo});
  background-size: contain;
`;

export const Form = styled.div`
  display: flex;
  width: 68rem;
  padding: 7.2rem 8rem;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.6rem;
  box-shadow: 0rem 0rem 4.4rem 0.6rem rgba(0, 0, 0, 0.12),
    0rem 0rem 0rem 0.01rem rgba(0, 0, 0, 0.03);
  position: absolute;
  background-color: ${newTheme.semantic.color.background.neutral100};
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  z-index: 10;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const Loader = styled(LoaderComponent)`
  height: 100%;
`;
