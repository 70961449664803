import styled from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { IFontSize } from '../../types/styled';
import imageSubscription from './assets/subscription.svg';
import imageDatabase from './assets/database.svg';

export type Logo = 'subscription' | 'database';

const logos: { [K in Logo]: string } = {
  subscription: imageSubscription,
  database: imageDatabase
};

export const Container = styled.div<{ marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
`;

export const Label = styled.p<{ fontSize: IFontSize; bold: boolean }>`
  white-space: pre-wrap;
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: ${({ fontSize, theme: { fontSizes } }) => fontSizes[fontSize]};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;

export const Description = styled.p<{ fontSize: IFontSize }>`
  white-space: pre-wrap;
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: ${({ fontSize, theme: { fontSizes } }) => fontSizes[fontSize]};
  margin-top: 0.8rem;
`;

export const PlusIcon = styled(SvgIcon)`
  margin-right: 0.2rem;
  height: 100%;
`;

export const LogoIcon = styled.div<{ logo: Logo }>`
  width: ${({ logo }) => (logo === 'subscription' ? '27.8rem' : '14rem')};
  height: ${({ logo }) => (logo === 'subscription' ? '17rem' : '22.5rem')};
  background-image: url(${({ logo }) => logos[logo]});
  background-size: contain;
  background-position: 50%;
  margin-bottom: 2.4rem;
  position: absolute;
  bottom: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 2.4rem;
`;
