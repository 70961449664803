import { useRef, useState, useEffect } from 'react';
import Chip from '../Chip';
import * as S from './style';
import { getHiddenList, displayListBasedOnContainerWidth } from './utils/chipListsManipulation';
import { useWindowDimensions } from '../../../hooks';
import { ChipItem } from '../../../types/interfaces';
import HiddenListNumber from '../../HiddenListNumber/HiddenListNumber';

interface IProps {
  itemList: ChipItem[];
  editable?: boolean;
  showTooltip?: boolean;
  className?: string;
}

const ChipList: React.FC<IProps> = (props) => {
  const { itemList, editable, showTooltip, className } = props;

  const [lists, setLists] = useState({
    displayed: [] as ChipItem[],
    hidden: []
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const { screenWidth } = useWindowDimensions();

  useEffect(() => {
    if (containerRef.current) {
      const { width: containerWidth } = containerRef.current.getBoundingClientRect();
      const displayedList = displayListBasedOnContainerWidth(
        [...itemList],
        containerWidth,
        editable
      );
      const hiddenList = getHiddenList([...itemList], [...displayedList]);
      setLists({ displayed: [...displayedList], hidden: [...hiddenList] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, screenWidth, itemList, editable]);

  return (
    <S.ChipList ref={containerRef} className={className}>
      {lists.displayed.map((item) => (
        <Chip
          key={item.key}
          text={item.label}
          cancelIcon={editable}
          removeChipHandler={item.onDelete}
          chipKey={item.key}
          iconName={item.iconName}
          shouldAddTooltip={item.shouldShowTooltip}
          tooltipText={item.tooltipText}
          isHiddenListShown={!!lists.hidden.length}
        />
      ))}
      {lists.hidden.length > 0 && (
        <S.HiddenListNumberWrapper data-testid="hidden-number-list">
          {showTooltip ? (
            <HiddenListNumber hiddenList={lists.hidden.map((item) => item.label)} />
          ) : (
            <S.Text>{`+${lists.hidden.length}`}</S.Text>
          )}
        </S.HiddenListNumberWrapper>
      )}
    </S.ChipList>
  );
};

export default ChipList;
