import { theme } from '@redislabsdev/redislabs-ui-components/styles';

const getTextWidthByPx = (
  text: string,
  fontSize: string = '1.4rem',
  fontFamily: string = theme.fontTypes.regular
) => {
  const font = `${fontSize} ${fontFamily}`;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const textWidth = context.measureText(text).width;

  return Math.ceil(textWidth);
};

export default getTextWidthByPx;
