import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { GlobalStyle } from '@redislabsdev/redislabs-ui-components/styles';
import { newTheme as theme } from '@redislabsdev/redis-ui-styles';
import { ConfirmationDialogContextProvider } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import '@redislabsdev/redislabs-ui-components/styles/ui-components-styles.css';
import '@redislabsdev/redis-ui-styles/normalized-styles.css';
import '@redislabsdev/redis-ui-styles/fonts.css';
import './app.css';
import { ToastContainer } from '@redislabsdev/redislabs-ui-components/ui/components/Toast';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components/macro';
import { routes } from 'utils/constants/routes';
import LogOut from 'screens/LogOut';
import CaptchaFrame from 'components/CaptchaFrame/CaptchaFrame';
import { TooltipProvider } from '@redislabsdev/redis-ui-components';
import { BotProtection } from 'components/BotProtection/BotProtection';
import i18n from './locale/i18n';
import store, { sagaMiddleware } from './store';
import rootSaga from './store/saga.root';
import history from './hashHistory';
import { ResponsiveRem } from './utils/style/responsive.style';
import { queryClient } from './utils/initializeQueryClient';
import SentryErrorBoundary from './services/sentry/components/SentryErrorBoundary';
import { NotificationContainer } from './components/Notification/Notification';
import AuthBroadcast from './services/broadcastChannels/AuthBroadcast/AuthBroadcast';
import InitializeOkta from './screens/AuthenticationWrappers/InitializeOkta';
import { CheckPendingTestConnection } from './screens/AuthenticationWrappers/CheckPendingTestConnection';
import SmAuthWrapper from './screens/AuthenticationWrappers/SmAuthWrapper';
import Routes from './screens/Routes';
import { LoadSystemParameters } from './screens/AuthenticationWrappers/LoadSystemParameters';
import { RedirectToSignUp } from './screens/RedirectToSignUp';

import { setConfirmationDialogContextValue } from './utils/helpers/confirmationDialogContextValue';
import ParseTokens from './screens/AuthenticationWrappers/ParseTokens';
import { LoadCurrentUserAndPermissions } from './screens/AuthenticationWrappers/LoadCurrentUserAndPermissions';
import ValidateCookiesEnabled from './components/ValidateCookiesEnabled/ValidateCookiesEnabled';

sagaMiddleware?.run(rootSaga);

interface AppProps {
  hideNavigation?: boolean;
}

function App({ hideNavigation }: AppProps) {
  AuthBroadcast.useChannel();

  return (
    <ThemeProvider theme={theme}>
      <TooltipProvider>
        <GlobalStyle />
        <CaptchaFrame />
        <I18nextProvider i18n={i18n}>
          <SentryErrorBoundary errorArea="main-app">
            <ValidateCookiesEnabled>
              <>
                <ConfirmationDialogContextProvider>
                  {(contextValues) => {
                    setConfirmationDialogContextValue(contextValues);

                    return (
                      <Provider store={store}>
                        <QueryClientProvider client={queryClient}>
                          <BotProtection />
                          <ResponsiveRem />
                          <LoadSystemParameters>
                            <Router history={history}>
                              <RedirectToSignUp>
                                <Switch>
                                  <Route exact path={routes.logout} component={LogOut} />
                                  <InitializeOkta>
                                    <ParseTokens>
                                      <LoadCurrentUserAndPermissions>
                                        <CheckPendingTestConnection>
                                          <SmAuthWrapper>
                                            <Routes hideNavigation={hideNavigation} />
                                          </SmAuthWrapper>
                                        </CheckPendingTestConnection>
                                      </LoadCurrentUserAndPermissions>
                                    </ParseTokens>
                                  </InitializeOkta>
                                </Switch>
                              </RedirectToSignUp>
                            </Router>
                          </LoadSystemParameters>
                          <ToastContainer />
                          <ReactQueryDevtools position="bottom-right" />
                        </QueryClientProvider>
                      </Provider>
                    );
                  }}
                </ConfirmationDialogContextProvider>
                <NotificationContainer />
              </>
            </ValidateCookiesEnabled>
          </SentryErrorBoundary>
        </I18nextProvider>
      </TooltipProvider>
    </ThemeProvider>
  );
}

export default App;
