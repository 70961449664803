import { ChipItem } from '../../../../types/interfaces';
import getTextWidthByPx from '../../../../utils/getTextWidthByPx';
import {
  INITIAL_PADDING_WIDTH,
  MARGIN_WIDTH,
  CHIP_PADDING_WIDTH,
  REMOVE_BUTTON_WIDTH,
  HIDDEN_NUMBER_WIDTH,
  LEFT_ICON_WIDTH
} from './constants';

export { getHiddenList } from '../../../../utils/generateLists';

export const displayListBasedOnContainerWidth = (
  allOptions: ChipItem[],
  containerWidth: number,
  editable: boolean
) => {
  const displayedList = [] as ChipItem[];
  let width = INITIAL_PADDING_WIDTH;
  for (let i = 0; i < allOptions.length && width < containerWidth; i++) {
    let itemWidth = 0;
    const textWidth = getTextWidthByPx(allOptions[i].label);
    itemWidth = textWidth + MARGIN_WIDTH + CHIP_PADDING_WIDTH;
    itemWidth += editable ? REMOVE_BUTTON_WIDTH : 0;
    itemWidth += allOptions[i].iconName ? LEFT_ICON_WIDTH : 0;

    if (
      width + itemWidth + (i < allOptions.length - 1 ? HIDDEN_NUMBER_WIDTH : 0) <
      containerWidth
    ) {
      displayedList.push(allOptions[i]);
      width += itemWidth;
    } else if (i === 0) {
      const firstLongTextChip = { ...allOptions[i] };
      firstLongTextChip.shouldShowTooltip = true;
      displayedList.push(firstLongTextChip);
      width += itemWidth;
      break;
    } else {
      break;
    }
  }

  return displayedList;
};
