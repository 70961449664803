import styled from 'styled-components/macro';
import { Loader as LoaderComponent } from '@redislabsdev/redislabs-ui-components';
import { newTheme } from '@redislabsdev/redis-ui-styles';

export const FormContainer = styled.div`
  background-color: ${newTheme.semantic.color.background.neutral100};
  display: flex;
  justify-content: center;
  position: absolute;
  top: 4rem;
  right: 4rem;
  border-radius: 1.6rem;
  width: 68rem;
  bottom: 4rem;
  padding: 7.2rem 16rem;
  box-shadow: 0px 0px 44px 6px ${newTheme.colors.shadow1};
  box-shadow: 0px 0px 0px 1px ${newTheme.colors.shadow3};
`;

export const Loader = styled(LoaderComponent)`
  height: 100vh;
`;

export const LoginLoaderWrapper = styled(Loader)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 90%);
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border-radius: 0.8rem;
`;
