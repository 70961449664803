import staticData from './staticData';

export const displayListBasedOnScreenWidth = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allOptions: any[],
  howManyOptionsToDisplay: number,
  screenWidth: number
) => {
  const displayedList = [];
  if (screenWidth <= staticData.minimumScreenWidth) {
    if (allOptions.length - howManyOptionsToDisplay > 0) {
      for (let i = 0; i < howManyOptionsToDisplay; i++) {
        displayedList.push(allOptions[i]);
      }
    } else {
      displayedList.push(...allOptions);
    }
  } else {
    displayedList.push(...allOptions);
  }

  return displayedList;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHiddenList = (completeList: any[], displayedList: any[]) => {
  const hiddenList = [];
  for (let i = displayedList.length; i < completeList.length; i++) {
    hiddenList.push(completeList[i]);
  }

  return hiddenList;
};
