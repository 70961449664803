import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Button, TextButton } from '@redislabsdev/redis-ui-components';
import { onboardingQueries } from 'queryClient/onboarding/onboarding.queries';
import { onboardingMutations } from 'queryClient/onboarding/onboarding.mutations';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/constants/routes';
import { useConnectionWizardClientStore } from 'components/ConnectionWizard/components/ConnectionWizardClient/store/useConnectionWizardClientStore';
import { usersMutations } from 'queryClient/users/users.mutations';
import { authIdSelector } from 'store/auth/auth.selectors';
import { useSelector } from 'react-redux';
import { usersQueries } from 'queryClient/users/users.queries';
import useNavigateToNewSubscription from 'hooks/useNavigateToNewSubscription';
import { subscriptionsQueries } from 'queryClient/subscriptions/subscriptions.queries';
import * as S from './Onboarding.style';
import TemplateSelect from './components/TemplateSelect/TemplateSelect';
import { TemplateSelectSelectedKeys } from './components/TemplateSelect/TemplateSelect.types';
import { useNewCustomerLoginStore } from '../store/useNewCustomerLoginStore';
import { stickersUtils } from './utils/onboardingStickersUtils';
import { RcPlanParams } from '../../../utils/getQueryParam/getQueryParams.types';

const Onboarding = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newCustomer.onboarding;
  const onboardingQuery = onboardingQueries.useOnboarding();
  const onboardingMutation = onboardingMutations.useOnboarding();
  const [selectedItems, setSelectedItems] = useState<TemplateSelectSelectedKeys>({});
  const navigateToNewSubscription = useNavigateToNewSubscription();
  const history = useHistory();
  const connectionWizardClientStore = useConnectionWizardClientStore();
  const { setIsFirstLogin } = useNewCustomerLoginStore();
  const userId = useSelector(authIdSelector);
  const { data: user } = usersQueries.useUser(Number(userId));
  const usersMutation = usersMutations.useUpdateUser(Number(userId));
  const subscriptionsQuery = subscriptionsQueries.useSubscriptions();
  const isSubscriptionsEmpty = (subscriptionsQuery?.data?.subscriptions || []).length === 0;
  const [stickerRole, setStickerRole] = useState<S.Role | null>(null);
  const [stickerClient, setStickerClient] = useState<S.Client | null>(null);

  const sendData = () => {
    sendOnboardingData();
    sendUserData();
  };

  const sendOnboardingData = () => {
    const data: OnboardingPostOptions = {
      options: selectedItems
    };

    onboardingMutation.mutate(data);
  };

  const sendUserData = () => {
    const role = selectedItems.role as string;

    const regexp = /[^A-Za-zÀ-ÖØ-öø-ÿ-'.]/g;
    user.first_name = user.first_name.replace(regexp, ' ');
    user.last_name = user.last_name.replace(regexp, ' ');

    usersMutation.mutate({ ...user, title: role });
  };

  const commonActions = () => {
    sendData();
    setIsFirstLogin(false);
    window.localStorage.removeItem('rcPlan');
  };

  const handleOnClickCreateFreeDatabase = () => {
    commonActions();
    history.push(routes.newCustomer.freeDb);
  };

  const handleOnClickContinue = () => {
    commonActions();
    history.push(routes.subscriptions.root);
  };

  const handleOnClickCreateCustomDatabase = () => {
    commonActions();
    navigateToNewSubscription();
  };

  const handleOnClickSetupFlexibleSubscription = () => {
    commonActions();
    history.push(routes.createSubscription.pro.create);
  };
  const handleOnClickSetupFixedSubscription = async () => {
    commonActions();
    history.push(routes.createSubscription.essential);
  };

  const handleOnChangeTemplateSelect = (selectedKeys: TemplateSelectSelectedKeys) => {
    setSelectedItems(selectedKeys);
    storeSelectedClient(selectedKeys);

    const role = stickersUtils.getRoleStickerKey(selectedKeys.role as string);
    const client = stickersUtils.getClientStickerKey(selectedKeys.client as string);

    setStickerRole(role);
    setStickerClient(client);
  };

  const storeSelectedClient = (selectedKeys: TemplateSelectSelectedKeys) => {
    const clientOptions = onboardingQuery.data.options?.client?.options || [];
    const client = clientOptions.find((v) => v.value === selectedKeys.client);
    const clientName = client?.readable || '';
    connectionWizardClientStore.setData(clientName);
  };

  const buttonsConfig = {
    freePlan: {
      textButton: {
        testId: 'know-your-customer--button-create-custom-database',
        onCLick: handleOnClickCreateCustomDatabase,
        label: t(keyPrefix.buttonCreateCustomDatabase)
      },
      mainButton: {
        testId: 'know-your-customer--button-create-free-database',
        onCLick: handleOnClickCreateFreeDatabase,
        label: t(keyPrefix.buttonCreateFreeDatabase)
      }
    },
    plan2: {
      textButton: {
        testId: 'know-your-customer--button-create-free-database',
        onCLick: handleOnClickCreateFreeDatabase,
        label: t(keyPrefix.buttonCreateFreeDatabase)
      },
      mainButton: {
        testId: 'know-your-customer--button-setup-fixed-subscription',
        onCLick: handleOnClickSetupFixedSubscription,
        label: t(keyPrefix.buttonStartFixedPlan)
      }
    },
    plan3: {
      textButton: {
        testId: 'know-your-customer--button-create-free-database',
        onCLick: handleOnClickCreateFreeDatabase,
        label: t(keyPrefix.buttonCreateFreeDatabase)
      },
      mainButton: {
        testId: 'know-your-customer--button-setup-flexible-subscription',
        onCLick: handleOnClickSetupFlexibleSubscription,
        label: t(keyPrefix.buttonStartFlexiblePlan)
      }
    }
  };

  const rcPlanFromStorage = window.localStorage.getItem('rcPlan') as RcPlanParams;
  const rcPlan =
    isSubscriptionsEmpty && Object.keys(buttonsConfig).includes(rcPlanFromStorage)
      ? rcPlanFromStorage
      : 'freePlan';

  if (subscriptionsQuery.isInitialLoading) {
    return <S.Loader />;
  }

  return (
    <S.Container data-testid="know-your-customer">
      <S.ImageLogo />
      <S.Stickers>
        <S.ImageRoles role={stickerRole} />
        <S.ImageClients client={stickerClient} />
      </S.Stickers>
      <S.Form>
        <S.Header>
          <S.Title size="XL">{t(keyPrefix.title)}</S.Title>
        </S.Header>
        <S.Paragraph>
          <S.SubTitle size="L">{t(keyPrefix.subTitle)}</S.SubTitle>
          <S.Sentence>
            {onboardingQuery.data && (
              <TemplateSelect
                template={onboardingQuery.data}
                onChange={handleOnChangeTemplateSelect}
                data-testid="know-your-customer--template-select"
              />
            )}
          </S.Sentence>
        </S.Paragraph>
        <S.Cta>
          <S.ButtonsContainer>
            <TextButton
              variant="primary"
              data-testid={buttonsConfig[rcPlan].textButton.testId}
              onClick={buttonsConfig[rcPlan].textButton.onCLick}
            >
              {buttonsConfig[rcPlan].textButton.label}
            </TextButton>
            {isSubscriptionsEmpty ? (
              <Button
                size="large"
                variant="primary"
                data-testid={buttonsConfig[rcPlan].mainButton.testId}
                onClick={buttonsConfig[rcPlan].mainButton.onCLick}
              >
                {buttonsConfig[rcPlan].mainButton.label}
              </Button>
            ) : (
              <Button
                size="large"
                variant="primary"
                data-testid="know-your-customer--button-continue"
                onClick={handleOnClickContinue}
              >
                {t(keyPrefix.buttonContinue)}
              </Button>
            )}
          </S.ButtonsContainer>
        </S.Cta>
      </S.Form>
    </S.Container>
  );
};

export default Onboarding;
