import { Config } from 'config';

const origins = Config.CURRENT_ORIGINS;

export type ApiPathsType = typeof ApiPaths[keyof typeof ApiPaths];

export const ApiPaths = {
  AA_CHANGE_REQUESTS: `${origins.SM}/AaChangeRequests`,
  AA_RCPS: `${origins.SM}/aaRcps`,
  ACCOUNT_MARKET_PLACES: `${origins.SM}/accountMarketplaces`,
  ACCOUNTS: `${origins.SM}/accounts`,
  BDBS: `${origins.SM}/bdbs`,
  BDB_METRICS: `${origins.SM}/bdbmetrics`,
  BILLINGS: `${origins.SM}/billings`,
  CLOUD_ACCOUNTS: `${origins.SM}/cloudAccounts`,
  TGW_RCPS: `${origins.TGW}/rcps`,
  CLUSTER_ROLES: `${origins.SM}/clusterRoles`,
  CLUSTER_USERS: `${origins.SM}/clusterUsers`,
  CLUSTERS: `${origins.SM}/clusters`,
  CONNECTION_WIZARD_TEMPLATES: `${origins.SM}/connectionWizardTemplates`,
  COUPONS: `${origins.SM}/coupons`,
  CRDBS: `${origins.SM}/crdbs`,
  CSRF: `${origins.SM}/csrf`,
  LOGIN: `${origins.SM}/login`,
  MFA: `${origins.SM}/mfa`,
  MODULES: `${origins.SM}/modules`,
  MW_SUBSCRIPTION: `${origins.MW}/subscription`,
  OFFBOARDING: `${origins.KYC}/offboarding`,
  OFFBOARDING_SM: `${origins.SM}/offboarding`,
  PAAS_EMAILS: `${origins.SM}/paas-emails`,
  PARAMETERS: `${origins.SM}/parameters`,
  PAYMENT_INFOS: `${origins.SM}/paymentInfos`,
  PENDO_INFO: `${origins.SM}/pendoInfo`,
  PLANS: `${origins.SM}/plans`,
  RCPS: `${origins.SM}/rcps`,
  REDIS_ACLS: `${origins.SM}/redisAcls`,
  SAML: `${origins.SM}/saml`,
  SESSION_LOGS: `${origins.SM}/sessionLogs`,
  SETUP_COMPLETENESS: `${origins.KYC}/setup-completeness`,
  ONBOARDING: `${origins.KYC}/onboarding`,
  SHARD_TYPE_PRICINGS: `${origins.SM}/shardTypePricings`,
  SHARD_TYPES: `${origins.SM}/shardTypes`,
  SHARDS_USAGE_REPORTS: `${origins.SM}/shardsUsageReports`,
  SLOW_LOGS: `${origins.SM}/slowlogs`,
  SUBSCRIPTION_MINIMAL_PRICINGS: `${origins.SM}/subscriptionMinimalPricings`,
  SUBSCRIPTIONS: `${origins.SM}/subscriptions`,
  SYSTEM_LOGS: `${origins.SM}/systemLogs`,
  USAGE_REPORTS: `${origins.SM}/usageReports`,
  USERS: `${origins.SM}/users`,
  COST_REPORT: `${origins.SM}/costReport`
} as const;

// ************************************************************
// Typescript check to verify that ApiPathsType is not a string
// If you get a type error here then check your changes to ApiPaths
// ************************************************************
const TypeCheckResult = (typeof {} as string extends ApiPathsType ? '' : string) === 'string'; // eslint-disable-line @typescript-eslint/no-unused-vars
