import { ApiPaths } from 'services/api/apiPaths';
import { createResourceApi } from '../../createResourceApi';

export const rcpsApi = createResourceApi(ApiPaths.RCPS, (rest) => ({
  create: (rcp: SubsRcp) => rest.post({ path: 'actions/plan', data: { rcp } }),

  update: (rcp: SubsRcp) => rest.put({ path: `${rcp.id}/actions/update`, data: { rcp } }),

  replanReserved: (rcp: SubsRcp) =>
    rest.post({ path: `${rcp.id}/actions/replan_reserved`, data: { rcp } }),

  validateProvision: (rcpId: number) =>
    rest.post({ path: `${rcpId}/actions/validate_provision_async/` }),

  validateProvisionStatus: (rcpId: number) =>
    rest.get<RcpValidateProvisionResponse>({
      path: `${rcpId}/get_validate_provision_status_async`
    }),

  planStatus: (rcpId: number) => rest.get<RcpPlanStatusResponse>({ path: `${rcpId}/plan_status` }),

  createPscEndpoint: (pscEndpointRequest: PscEndpointRequest) =>
    rest.post<number>({
      path: `${pscEndpointRequest.rcpId}/psc/endpoint`,
      data: { vpcPscEndpoint: pscEndpointRequest.pscEndpoint }
    }),

  updatePscEndpoint: (pscEndpointRequest: PscEndpointRequest) =>
    rest.put({
      path: `${pscEndpointRequest.rcpId}/psc/endpoint/${pscEndpointRequest.pscEndpoint.id}`,
      data: { vpcPscEndpoint: pscEndpointRequest.pscEndpoint }
    }),

  createPsc: (rcpId: number) => rest.post<number>({ path: `${rcpId}/psc` }),

  deletePsc: (rcpId: number) => rest.delete<number>({ path: `${rcpId}/psc` }),

  deletePscEndpoint: ({ rcpId, endpointId }: { rcpId: number; endpointId: number }) => {
    return rest.delete<number>({ path: `${rcpId}/psc/endpoint/${endpointId}` });
  },

  getPsc: (rcpId: number) => rest.get<{ vpcPscService: Psc }>({ path: `${rcpId}/psc` }),

  getPscCreationScript: (pscEndpointRequest: PscEndpointRequest) =>
    rest.get<{ vpcPscEndpoint: PscCreationScript }>({
      path: `${pscEndpointRequest.rcpId}/psc/endpoint/${pscEndpointRequest?.pscEndpoint?.id}/script/creation`
    }),
  getPscDeletionScript: ({ rcpId, endpointId }: { rcpId: number; endpointId: number }) =>
    rest.get<{ vpcPscEndpoint: PscCreationScript }>({
      path: `${rcpId}/psc/endpoint/${endpointId}/script/deletion`
    }),

  getPscEndpoints: (rcpId: number) =>
    rest.get<{ vpcPscEndpoints: PscEndpointResponse[] }>({ path: `${rcpId}/psc/endpoint` }),

  getRedisVersion: () => rest.get<RedisVersionResponse>({ path: 'redis_version' })
}));
