import {
  activeRcpSubscriptionStatuses,
  deletingAaRcpStatuses,
  deletingRcpStatuses,
  rcpValidateProvisionStatuses
} from '../constants/subscription.constants';
import { isSubAa, isSubFlexible, isSubReserved } from './subType';

export const isSubscriptionNotActive = (selectedSub: Subscription) => {
  if (isSubFlexible(selectedSub)) {
    return !isSubFlexibleActive(selectedSub);
  }
  if (isSubAa(selectedSub)) {
    return selectedSub.aa_rcp.status !== 'active';
  }

  return selectedSub.status !== 'active';
};

export const isRcpClusterExists = (rcp: SubsRcp) => {
  return Boolean(rcp?.cluster?.url);
};

export const isSubFlexibleActive = (selectedSub: Subscription) => {
  return (
    activeRcpSubscriptionStatuses.includes(selectedSub.rcp.status) ||
    isRcpUpdateFailedOrTerminatedOnValidateProvision(selectedSub.rcp)
  );
};

export const isRcpUpdateFailedOrTerminatedOnValidateProvision = (rcp: SubsRcp) => {
  return rcpValidateProvisionStatuses.includes(rcp.status) && isRcpClusterExists(rcp);
};

export const isSubscriptionActive = (selectedSub: Subscription) =>
  !isSubscriptionNotActive(selectedSub);

export const isSubDeleting = (sub: Subscription) => {
  if (sub?.status === 'deleted') {
    return true;
  }

  if (sub?.rcp?.status && deletingRcpStatuses.includes(sub.rcp.status)) {
    return true;
  }

  if (sub?.aa_rcp?.status && deletingAaRcpStatuses.includes(sub.aa_rcp.status)) {
    return true;
  }

  return false;
};

export const getSubscriptionStatus = (subscription: Subscription) => {
  if (isSubAa(subscription)) return subscription.aa_rcp?.status;
  if (isSubFlexible(subscription)) return subscription.rcp.status;

  return subscription.status;
};

export const isSubscriptionReservedPending = (subscription: Subscription) => {
  if (isSubReserved(subscription)) {
    return subscription.status === 'pending';
  }

  return false;
};

export const isSubscriptionProvisionError = (subscription: Subscription) => {
  if (!subscription.rcp) {
    return false;
  }

  return (
    subscription.rcp.status === 'provision_error' ||
    (subscription.rcp.status === 'validate_provision_error' &&
      !isRcpClusterExists(subscription.rcp))
  );
};
