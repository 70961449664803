import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { PRIVACY_LINK, TERMS_LINK } from 'utils/constants/docLinks';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import { useSelector } from 'react-redux';
import useSamlRedirectNotification from '../../../Login/hooks/useShowSamlNotifications';
import useAwsMarketPlaceQueryParamsMessage from '../../../Login/hooks/useAwsMarketPlaceQueryParamsMessage';
import { parametersQueries } from '../../../../queryClient/parameters/parameters.queries';
import * as S from './SignInForm.style';
import SignInWithEmailForm from './components/SignInWithEmail/SignInWithEmailForm';
import DontHaveAnAccount from './components/DontHaveAnAccount/DontHaveAnAccout';
import OrSeparator from '../OrSeperator/OrSeparator';
import { mfaPayloadSelector } from '../../../../store/auth/auth.selectors';
import MfaLoginModal from '../MfaLogin/MfaLoginModal';
import SocialSignIn from '../SocialLogin/SocialLogin';

const SignInForm = () => {
  useAwsMarketPlaceQueryParamsMessage();
  useSamlRedirectNotification();
  const { systemParams } = parametersQueries.useSystemParameters();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;
  const samlRedirectAndNotification = window.sessionStorage.getItem('samlRedirectAndNotification');
  const { setIsFirstLogin } = useNewCustomerLoginStore();

  const mfaParams = useSelector(mfaPayloadSelector);
  let isBothMfaMethodsExists = false;

  if (mfaParams) {
    isBothMfaMethodsExists = mfaParams.smsFactorAvailable && mfaParams.totpFactorAvailable;
  }

  useEffect(() => {
    setIsFirstLogin(false);
  }, []);

  return (
    <S.SignInFormContainer>
      {mfaParams && <MfaLoginModal isBothMfaMethodsExists={isBothMfaMethodsExists} />}
      <S.SignInTitle>{t(keyPrefix.signIn)}</S.SignInTitle>
      <SocialSignIn
        allowSSo={samlRedirectAndNotification !== undefined || systemParams?.saml_enabled}
      />
      <OrSeparator />
      <SignInWithEmailForm />
      <S.SignupContainer>
        <DontHaveAnAccount />
        <Trans
          i18nKey={keyPrefix.legalContent}
          components={{
            a1: <a href={TERMS_LINK}>text</a>,
            a2: <a href={PRIVACY_LINK}>text</a>
          }}
        />
      </S.SignupContainer>
    </S.SignInFormContainer>
  );
};
export default SignInForm;
