import { ApiPaths } from 'services/api/apiPaths';
import { createResourceApi } from '../../createResourceApi';

export const mwSubscriptionApi = createResourceApi(ApiPaths.MW_SUBSCRIPTION, (rest) => ({
  getOne: (id: number) =>
    rest.get<MwSubscriptionData>({
      path: `${id}`
    }),

  update: ({ id, maintenanceWindow }) =>
    rest.put<MwSubscriptionUpdateData>({
      path: `${id}`,
      data: maintenanceWindow
    }),

  getOptInToRedisUpgrade: (subscriptionId: number) =>
    rest.get<MwSubscriptionOptInToRedisUpgrade>({
      path: `${subscriptionId}/opt-in-to-redis-upgrade`
    }),

  updateOptInToRedisUpgrade: (subscriptionId: number) =>
    rest.post({
      path: `${subscriptionId}/opt-in-to-redis-upgrade`
    }),

  skipMaintenance: (subscriptionId: number) =>
    rest.post<MwSkipMaintenanceData>({
      path: `${subscriptionId}/skip`
    })
}));
