/* eslint-disable @typescript-eslint/naming-convention */
import { takeLatest, call, delay, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import * as proApiCalls from './pro.calls';
import { rcpsApi } from '../../../services/api/resources/rcps/rcps.resource';
import * as aaSubscriptionCalls from './aaSubscription.calls';
import * as planActions from '../../subscriptions/plans/plans.actions';
import { planCallsInterval } from '../../../utils/constants/api/intervals';
import {
  postProPlanSuccess,
  postProPlanFailure,
  postProSubscriptionFailure,
  postProSubscriptionSuccess,
  calculatePriceFailure,
  calculatePriceSuccess,
  createAASubscriptionFailure,
  createAASubscriptionSuccess
} from './pro.actions';
import { rcpErrorStatuses } from './pro.constants';

import {
  ProActionTypes,
  PostProPlanRequestAction,
  PostProSubscriptionRequestAction,
  ShardTypePricingListResponse,
  CalculatePriceRequestAction,
  AaChangeRequestPayload,
  ExecuteAaSubsCallParams,
  CreateAASubscriptionRequestAction
} from './pro.types';
import history from '../../../hashHistory';
import { buildCalculatePricePostBody, findMinimalPricingForMultiRegions } from './pro.utils';
import { buildPath, routes } from '../../../utils/constants/routes';
import { addNewSubscription } from '../../subscriptions/subscriptions.actions';
import { isCloudAccountExternal } from '../../../screens/CreateSubscription/SubscriptionTypes/Pro/utils/isCloudAccountExternal';
import createApiErrorResponse from '../../../utils/createApiErrorResponse';
import { extractErrorAndShowToast } from '../../../utils/helpers/extractErrorAndShowToast';
import findRegionPriceItem from '../../../utils/cloudPricings/findRegionPriceItem';
import getCalculatorTotalPrice from '../../../utils/cloudPricings/getCalculatorTotalPrice';
import { subscriptionsApi } from '../../../services/api/resources/subscriptions/subscriptions.resource';
import { extractErrorCode } from '../../../utils/helpers/extractError';
import i18n from '../../../locale/i18n';
import { ErrorsMapCodes } from '../../../utils/constants/api/errorsMap';
import { NotificationButtonProps } from '../../../components/Notification/Notification.types';
import { insertMaintenanceWindow } from './pro.maintenanceWindow.calls';

function* postProPlanSaga(action: PostProPlanRequestAction) {
  const {
    payload: { subsRcp: rcp, openSupportPageAction, isSupportPageLoading }
  } = action;

  try {
    const { data: rcpId }: AxiosResponse<number> = yield call(rcpsApi.create, rcp);

    yield delay(planCallsInterval);
    while (true) {
      const { data: proPlan }: AxiosResponse<RcpPlanStatusResponse> = yield call(
        rcpsApi.planStatus,
        rcpId
      );
      if (proPlan.status === 'plan_done') {
        yield put(postProPlanSuccess({ rcpId, proPlan }));
        break;
      }

      if (rcpErrorStatuses.some((status) => status === proPlan.status)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw createApiErrorResponse(proPlan.error_code);
      }

      yield delay(planCallsInterval);
    }
  } catch (e) {
    yield put(postProPlanFailure());
    const errorCode = extractErrorCode(e.response.data);
    const PLAN_ERRORS_WITH_ACTION: ErrorsMapCodes[] = [
      'rcp-cannot-place-shard-new',
      'rcp-no-valid-cluster-options-new'
    ];
    let buttonProps: NotificationButtonProps;
    if (PLAN_ERRORS_WITH_ACTION.includes(errorCode)) {
      buttonProps = {
        content: i18n.t('subscriptionDetails.contactSupport'),
        onClick: openSupportPageAction,
        disabled: isSupportPageLoading
      };
    }
    extractErrorAndShowToast(e, buttonProps);
  }
}

function* postProSubscription(action: PostProSubscriptionRequestAction) {
  try {
    const {
      data: { subscription }
    }: AxiosResponse<{ subscription: Subscription }> = yield call(subscriptionsApi.create, {
      subscription: omit(action.payload, 'maintenance_window')
    });

    if (action.payload.maintenance_window) {
      yield insertMaintenanceWindow(subscription.id, action.payload.maintenance_window);
    }

    yield put(postProSubscriptionSuccess());
    yield put(planActions.getPlans());
    subscription.rcp.status = 'provision_pending';
    yield put(addNewSubscription(subscription));

    history.push(
      buildPath(routes.subscriptions.subscription.db.root, { subscriptionId: subscription.id })
    );
  } catch (e) {
    yield put(postProSubscriptionFailure());
    extractErrorAndShowToast(e);
  }
}

function* calculatePriceSaga({ payload }: CalculatePriceRequestAction) {
  const { databases_to_create, cloud_account } = payload;

  try {
    if (databases_to_create.length) {
      const calculatePricePostBody = buildCalculatePricePostBody(payload);
      const {
        data: { shardTypePricings }
      }: AxiosResponse<ShardTypePricingListResponse> = yield call(
        proApiCalls.getShardPricingList,
        calculatePricePostBody
      );
      const price = getCalculatorTotalPrice({
        ...payload,
        databases: payload.databases_to_create,
        shardTypePricings,
        isExternal: isCloudAccountExternal(payload.cloud_account)
      });

      yield put(calculatePriceSuccess({ price, shardTypePricingList: shardTypePricings }));
    } else {
      let minimalCalculatedPrice = 0;
      let comparedPrice = 0;
      let comparedSize = 0;

      if (!isCloudAccountExternal(cloud_account)) {
        if (payload.isAA) {
          minimalCalculatedPrice = findMinimalPricingForMultiRegions(payload);
        } else {
          const regionPriceItem = findRegionPriceItem({ ...payload, regionName: payload.region });
          if (regionPriceItem) {
            minimalCalculatedPrice = regionPriceItem.price;
            if (payload.rof) {
              comparedPrice = regionPriceItem.compared_price;
              comparedSize = regionPriceItem.compared_size;
            }
          }
        }
      }
      yield put(
        calculatePriceSuccess({
          price: minimalCalculatedPrice,
          comparedPrice,
          comparedSize
        })
      );
    }
  } catch (e) {
    if (e.response) {
      yield put(calculatePriceFailure(e.response?.statusText));
      extractErrorAndShowToast(e);
    }
  }
}

function* executeAASubscription(params: ExecuteAaSubsCallParams) {
  try {
    const {
      data
    }: AxiosResponse<{
      aaChangeRequest: AaChangeRequestPayload;
    }> = yield call(aaSubscriptionCalls.executeAaChangeRequest, params);

    const subscriptionId = data.aaChangeRequest.subscription_id;
    const { data: subsResponse }: AxiosResponse<{ subscription: Subscription }> = yield call(
      subscriptionsApi.getOne,
      subscriptionId
    );

    yield put(planActions.getPlans());
    yield put(addNewSubscription(subsResponse.subscription));
    yield put(createAASubscriptionSuccess());

    history.push(
      buildPath(routes.subscriptions.subscription.db.root, {
        subscriptionId
      })
    );

    return data.aaChangeRequest;
  } catch (e) {
    if (e.response) {
      yield put(createAASubscriptionFailure(e.response?.message));
      extractErrorAndShowToast(e);
    }
  }

  return null;
}

function* getAAChangeRequestStatus(params: ExecuteAaSubsCallParams) {
  yield delay(planCallsInterval);

  try {
    while (true) {
      const {
        data: { aaChangeRequest }
      }: AxiosResponse<{
        aaChangeRequest: AaChangeRequestPayload;
      }> = yield call(aaSubscriptionCalls.getAAChangeRequestStatus, params.aaChangeRequestId);

      const responseStatus = aaChangeRequest.status;

      if (responseStatus === 'plan_done_provision_needed') {
        return yield executeAASubscription(params);
      }

      if (responseStatus === 'plan_failed') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw createApiErrorResponse('plan-failed');
      }

      yield delay(planCallsInterval);
    }
  } catch (e) {
    const message = e.response?.message || e.message;
    yield put(createAASubscriptionFailure(message));
    extractErrorAndShowToast(e);
  }

  return null;
}

function* createAAChangeRequest({ payload }: CreateAASubscriptionRequestAction) {
  try {
    const {
      data
    }: AxiosResponse<{
      aaChangeRequest: AaChangeRequestPayload;
    }> = yield call(aaSubscriptionCalls.aaChangeRequest, {
      aaChangeRequest: payload.aaChangeBodyPayload
    });

    const aaChangeRequestId = data.aaChangeRequest.id;
    const finalAaChangeRequest: AaChangeRequestPayload | null = yield getAAChangeRequestStatus({
      aaChangeRequestId,
      subscriptionName: payload.subscriptionName,
      paymentId: payload.paymentId,
      isMarketplace: payload.isMarketplace
    });

    if (payload.maintenanceWindow?.customWindow && finalAaChangeRequest?.subscription_id) {
      yield insertMaintenanceWindow(
        finalAaChangeRequest.subscription_id,
        payload.maintenanceWindow
      );
    }
  } catch (e) {
    if (e.response) {
      yield put(createAASubscriptionFailure(e.response?.message));
      extractErrorAndShowToast(e);
    }
  }
}

export default function* proSaga() {
  yield takeLatest(ProActionTypes.POST_PRO_PLAN_REQUEST, postProPlanSaga);
  yield takeLatest(ProActionTypes.POST_PRO_SUBSCRIPTION_REQUEST, postProSubscription);
  yield takeLatest(ProActionTypes.CALCULATE_PRICE_REQUEST, calculatePriceSaga);
  yield takeLatest(ProActionTypes.CREATE_AA_SUBSCRIPTION_REQUEST, createAAChangeRequest);
}
