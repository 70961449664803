import styled from 'styled-components/macro';
import loginImage1 from 'assets/icons/loginBackground/Login1.svg';
import loginImage2 from 'assets/icons/loginBackground/Login2.svg';
import loginImage3 from 'assets/icons/loginBackground/Login3.svg';
import redisLogo from 'assets/icons/RedisBlackLogo.svg';

export const BackgroundImageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  align-items: center;
`;

export const RedisLogo = styled.div`
  width: 10.1rem;
  height: 4rem;
  position: absolute;
  top: 3rem;
  left: 3rem;
  background: url(${redisLogo}) no-repeat;
`;
export const BackgroundImage = styled.div<{ imageCounter?: number }>`
  width: 100%;
  height: 100%;
  animation: imageSwitching 24s infinite ease forwards;
  background-size: cover;
  background-position: 0% 100%;
  background-image: url(${loginImage1}), url(${loginImage2}), url(${loginImage3});

  @keyframes imageSwitching {
    0% {
      background-image: url(${loginImage1});
    }
    27% {
      background-image: url(${loginImage1});
    }
    30% {
      background-image: url(${loginImage2});
    }
    72% {
      background-image: url(${loginImage2});
    }
    75% {
      background-image: url(${loginImage3});
    }
    97% {
      background-image: url(${loginImage3});
    }
    100% {
      background-image: url(${loginImage1});
    }
  }
`;
