import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const InputHint = styled(Typography.Body)`
  margin-bottom: 1.2rem;
  margin-top: 3rem;
`;
export const DidNotReceiveTheCodeContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 1rem;
`;
export const DidNotReceiveTheCodeText = styled.div`
  margin-top: 0.5rem;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 21rem;
  margin-top: 6rem;
`;
