import { ComponentType } from 'react';
import SentryErrorBoundary from 'services/sentry/components/SentryErrorBoundary';
import { SentryErrorAreaTagValues } from 'services/sentry/sentry.types';

export function createMFEWrapper<T>(
  Component: ComponentType<T>,
  errorArea: SentryErrorAreaTagValues
) {
  return (props: T) => (
    <SentryErrorBoundary
      onError={() => {
        window.location.hash = '#/';
      }}
      errorArea={errorArea}
    >
      <Component {...props} />
    </SentryErrorBoundary>
  );
}
