import { Input } from '@redislabsdev/redislabs-ui-components';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { ApiPaths } from 'services/api/apiPaths';
import { Button, Modal, TextButton } from '@redislabsdev/redis-ui-components';
import { Form, Formik } from 'formik';
import { mfaPayloadSelector } from '../../../../../../store/auth/auth.selectors';
import RedisApiAxiosInstance from '../../../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { extractErrorAndShowToast } from '../../../../../../utils/helpers/extractErrorAndShowToast';
import * as S from './SmsFactorForm.style';
import useFactorChange from '../../hooks/useFactorChange';
import useMfaForm from '../../hooks/useMfaForm';

interface Props {
  switchToAuthAppModal: () => void;
}

const SmsFactorForm = ({ switchToAuthAppModal }: Props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.mfaLogin.smsAuthenticationModal;
  const { phoneNumber, totpFactorAvailable, username, password } = useSelector(mfaPayloadSelector);
  const status = useFactorChange('SMS');
  const { handleAuthFactorSubmit } = useMfaForm();
  const reSendMfaCode = async () => {
    let authName = username?.toLowerCase();
    try {
      if (authState.isAuthenticated) {
        const user = await oktaAuth.getUser();
        authName = user?.email;
      }
      await RedisApiAxiosInstance.post(`${ApiPaths.LOGIN}/resend_mfa_code`, {
        username: authName,
        password
      });
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };
  const handleSubmit = ({ authCode }: { authCode: string }) => {
    handleAuthFactorSubmit('SMS', authCode);
  };

  return (
    <Formik initialValues={{ authCode: '' }} onSubmit={handleSubmit}>
      {({ handleChange, dirty }) => (
        <Form>
          <Modal.Content.Header title={t(keyPrefix.title)} />
          <Modal.Content.Body.Compose>
            <S.InputHint>{t(keyPrefix.enterCode, { phoneNumber })}</S.InputHint>
            <Input
              data-testid="sms-code"
              id="sms-code"
              name="authCode"
              width="100%"
              onChange={handleChange}
            />
            <S.DidNotReceiveTheCodeContainer>
              <S.DidNotReceiveTheCodeText>{t(keyPrefix.didntReceive)}</S.DidNotReceiveTheCodeText>
              <TextButton data-testid="button--sms-code-again" onClick={reSendMfaCode}>
                {t(keyPrefix.sendCodeAgain)}
              </TextButton>
            </S.DidNotReceiveTheCodeContainer>
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer.Compose>
            <S.ButtonsContainer>
              {totpFactorAvailable && (
                <TextButton onClick={switchToAuthAppModal}>{t(keyPrefix.useAuthApp)}</TextButton>
              )}
              <Button
                type="submit"
                disabled={!dirty || (totpFactorAvailable && status !== 'success')}
              >
                {t('LoginScreen.mfaLogin.verify')}
              </Button>
            </S.ButtonsContainer>
          </Modal.Content.Footer.Compose>
        </Form>
      )}
    </Formik>
  );
};

export default SmsFactorForm;
