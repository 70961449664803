import * as S from './LoginBackground.style';

const LoginBackground = ({ children }) => {
  return (
    <S.BackgroundImageContainer>
      <S.RedisLogo />
      <S.BackgroundImage />
      {children}
    </S.BackgroundImageContainer>
  );
};

export default LoginBackground;
