import { useMutation } from '@tanstack/react-query';
import { onboardingApi } from '../../services/api/resources/onboarding/onboarding.resource';
import onboardingKeys from './onboarding.keys';

const useOnboardingMutation = () => {
  const mutationFn = async (data: OnboardingPostOptions) => {
    await onboardingApi.postOptions(data);
  };

  return useMutation({
    mutationKey: onboardingKeys.postOptions,
    mutationFn
  });
};

export const onboardingMutations = {
  useOnboarding: useOnboardingMutation
};
