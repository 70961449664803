import Lottie from 'react-lottie-player';
import FreeDBLoaderAnimation from '../../../../../assets/lottie/loader-freeDB.json';
import * as S from './FreeDBLoaderAnimation.style';

const defaultOptions = {
  loop: true,
  play: true,
  animationData: FreeDBLoaderAnimation,
  speed: 1.3
};

const FreeDBCreateAnimation = () => {
  return (
    <S.FreeDBLoaderContainer data-testid="free-db-loader">
      <Lottie {...defaultOptions} />
    </S.FreeDBLoaderContainer>
  );
};

export default FreeDBCreateAnimation;
