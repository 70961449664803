export const activeRcpSubscriptionStatuses: RcpStatus[] = ['provision_done', 'update_done'];

export const rcpValidateProvisionStatuses: RcpStatus[] = [
  'validate_provision_done',
  'validate_provision_error'
];

export const rcpDeleteSubscriptionForbiddenStatuses: RcpStatus[] = [
  'validate_provision_queued',
  'validate_provision_pending',
  'provision_queued',
  'update_pending',
  'provision_pending',
  'plan_queued',
  'plan_pending'
];

export const deletingAaRcpStatuses: AARcpStatus[] = [
  'delete_done',
  'delete_pending',
  'delete_queued'
];

export const deletingRcpStatuses: RcpStatus[] = [
  'destroy_done',
  'destroy_pending',
  'destroy_queued'
];
