import styled, { css } from 'styled-components/macro';
import { Loader as LoaderComponent } from '@redislabsdev/redislabs-ui-components';
import { newTheme } from '@redislabsdev/redis-ui-styles';
import { Typography } from '@redislabsdev/redis-ui-components';
import imageLogo from '../assets/logo.svg';
import imageBg from './assets/bg.svg';
import imageClientJava from './assets/clients/clientJava.svg';
import imageClientNet from './assets/clients/clientNet.svg';
import imageClientNodejs from './assets/clients/clientNodejs.svg';
import imageClientOther from './assets/clients/clientOther.svg';
import imageClientPython from './assets/clients/clientPython.svg';
import imageRoleArchitect from './assets/roles/roleArchitect.svg';
import imageRoleBusinessAnalystDataScientist from './assets/roles/roleBusinessAnalystDataScientist.svg';
import imageRoleBusinessExecutive from './assets/roles/roleBusinessExecutive.svg';
import imageRoleDbaDevOpsTo from './assets/roles/roleDbaDevOpsTo.svg';
import imageRoleDigitalInnovationExecutive from './assets/roles/roleDigitalInnovationExecutive.svg';
import imageRoleItExecutive from './assets/roles/roleItExecutive.svg';
import imageRoleManagerOfDevelopmentSoftwareDeveloper from './assets/roles/roleManagerOfDevelopmentSoftwareDeveloper.svg';
import imageRoleOther from './assets/roles/roleOther.svg';
import imageRoleProductLeaderProjectProductManager from './assets/roles/roleProductLeaderProjectProductManager.svg';
import imageRoleStudent from './assets/roles/roleStudent.svg';

const clients = {
  clientJava: imageClientJava,
  clientNet: imageClientNet,
  clientNodejs: imageClientNodejs,
  clientOther: imageClientOther,
  clientPython: imageClientPython
} as const;

export type Client = keyof typeof clients;

const roles = {
  roleArchitect: imageRoleArchitect,
  roleBusinessAnalystDataScientist: imageRoleBusinessAnalystDataScientist,
  roleBusinessExecutive: imageRoleBusinessExecutive,
  roleDbaDevOpsTo: imageRoleDbaDevOpsTo,
  roleDigitalInnovationExecutive: imageRoleDigitalInnovationExecutive,
  roleItExecutive: imageRoleItExecutive,
  roleManagerOfDevelopmentSoftwareDeveloper: imageRoleManagerOfDevelopmentSoftwareDeveloper,
  roleOther: imageRoleOther,
  roleProductLeaderProjectProductManager: imageRoleProductLeaderProjectProductManager,
  roleStuden: imageRoleStudent
} as const;

export type Role = keyof typeof roles;

export const Loader = styled(LoaderComponent)`
  height: 100vh;
`;

export const Container = styled.div`
  position: absolute;
  z-index: 6;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f2f3fc;
  background-image: url(${imageBg});
  background-size: cover;
  background-position: 0% 50%;
`;

export const Form = styled.div`
  display: flex;
  width: 68rem;
  padding: 7.2rem 8rem;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.6rem;
  box-shadow: 0rem 0rem 4.4rem 0.6rem rgba(0, 0, 0, 0.12),
    0rem 0rem 0rem 0.01rem rgba(0, 0, 0, 0.03);
  position: absolute;
  background-color: ${newTheme.semantic.color.background.neutral100};
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  z-index: 10;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 8.8rem;
`;

export const Cta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 8.8rem;
`;

export const Title = styled(Typography.Heading)`
  color: ${newTheme.semantic.color.text.neutral800};
`;

export const SubTitle = styled(Typography.Body)`
  color: ${newTheme.semantic.color.text.neutral700};
  align-self: stretch;
`;

export const Sentence = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 4rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: end;
  justify-content: space-between;
`;

export const ImageLogo = styled.div`
  width: 10.3rem;
  height: 3.5rem;
  position: absolute;
  top: 4rem;
  left: 4.7rem;

  background-image: url(${imageLogo});
  background-size: contain;
`;

export const Stickers = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

const ImageSticker = css`
  background-size: cover;
  background-position: 0% 50%;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

export const ImageClients = styled.div<{ client: Client }>`
  ${ImageSticker};
  background-image: url(${({ client }) => clients[client]});
`;

export const ImageRoles = styled.div<{ role: Role }>`
  ${ImageSticker};
  background-image: url(${({ role }) => roles[role]});
`;
