import { parametersQueries } from 'queryClient/parameters/parameters.queries';
import { useDispatch } from 'react-redux';
import { showNotification } from 'components/Notification/Notification';
import { getModulesByClusterId, getClustersBySubId } from '../../../utils/freeDb.calls';
import { buildFreeDatabaseBody } from '../../../utils/loginRelated';
import { createUpdateDBRequest } from '../../../../../../store/databases/databases.actions';
import { extractErrorAndShowToast } from '../../../../../../utils/helpers/extractErrorAndShowToast';
import { postEssentialPlanRequest } from '../../../../../../store/createSubscription/essentials/essentials.actions';
import i18n from '../../../../../../locale/i18n';
import useAccount from '../../../../../../hooks/useAccount';
import { unwantedDbNameCharRegex } from '../../../../../../utils/validations/databasesValidations';
import { MAX_DB_NAME_LENGTH } from '../../../../../../utils/constants/SubscriptionValidations';
import {
  CreateFreeDbProps,
  HandleFreeSubSuccessProps,
  UseFreeDbFormProps
} from '../FreeDbForm.types';

const useFreeDbForm = ({
  freePlans,
  vendorRegionPlanMapper,
  selectedRegion,
  selectedVendor
}: UseFreeDbFormProps) => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const { account } = useAccount();
  const dispatch = useDispatch();

  // max allowed name for free subscription is MAX_DB_NAME_LENGTH (18 is ' free subscription' length)
  const accountNameForDB = account?.name
    ? `${account.name.replace(unwantedDbNameCharRegex, '')}`
    : '';
  const subName = account?.name ? `${account.name.substring(0, MAX_DB_NAME_LENGTH - 18)} f` : 'F';
  const dbName = accountNameForDB.length
    ? `${accountNameForDB.substring(0, MAX_DB_NAME_LENGTH - 8)}-f`
    : 'F';

  const handleFreeSubSuccess = async ({
    subscription,
    handleFreeDbError,
    handleFreeDbSuccess
  }: HandleFreeSubSuccessProps) => {
    const plan = freePlans.find(
      (essPlan) => essPlan.id === vendorRegionPlanMapper[selectedVendor][selectedRegion]
    );

    try {
      const cluster = await getClustersBySubId(subscription.id);
      let modules = [];
      const isRedisStackSupported =
        systemParams.is_redis_stack_supported &&
        cluster?.supports_redis_stack &&
        plan?.supports_redis_stack;

      if (isRedisStackSupported) {
        modules = await getModulesByClusterId(cluster.id);
      }

      const databaseBody = buildFreeDatabaseBody({
        cluster,
        modules,
        selectedSubscription: subscription,
        plan,
        isRedisStackSupported
      });

      dispatch(
        createUpdateDBRequest({
          shouldUpdateRcp: false,
          updatedBdb: {
            ...databaseBody,
            name: `${dbName}ree-db`
          },
          successCb: (bdb) => {
            handleFreeDbSuccess(bdb);
          },
          errorCb: (e) => {
            showNotification({ body: i18n.t(e.message) }, 'error');
            handleFreeDbError(subscription.id);
          },
          isCreateMode: true,
          selectedSubscription: subscription
        })
      );
    } catch (e) {
      extractErrorAndShowToast(e);
      handleFreeDbError(subscription.id);
    }
  };

  const createFreeDb = ({
    handleFreeSubError,
    handleFreeDbError,
    handleFreeDbSuccess
  }: CreateFreeDbProps) => {
    dispatch(
      postEssentialPlanRequest({
        name: `${subName}ree subscription`,
        plan: vendorRegionPlanMapper[selectedVendor][selectedRegion].toString(),
        recurring_payment_info: null,
        successCb: (subscription) => {
          handleFreeSubSuccess({ subscription, handleFreeDbError, handleFreeDbSuccess });
        },
        errorCb: handleFreeSubError
      })
    );
  };

  return { createFreeDb };
};

export default useFreeDbForm;
