import create from 'zustand';

interface ConnectionWizardClientStore {
  clientName: string;
  setData: (clientName: string) => void;
}

const initialState = {
  clientName: null
};

export const useConnectionWizardClientStore = create<ConnectionWizardClientStore>()((set) => ({
  ...initialState,
  setData: (clientName) => set(() => ({ clientName }))
}));
