import { Button, TextButton } from '@redislabsdev/redis-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import analytics from 'services/analytics/analytics';
import { trackSignUp } from 'services/analytics/ga/general';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useHistory } from 'react-router-dom';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import useNavigateToNewSubscription from 'hooks/useNavigateToNewSubscription';
import { usersQueries } from 'queryClient/users/users.queries';
import { FreeDbFormProps } from '../../FreeDb.types';
import * as S from './FreeDbForm.style';
import FreeDbCloudVendor from '../FreeDbCloudVendor/FreeDbCloudVendor';
import { authIdSelector, productTypeSelector } from '../../../../../store/auth/auth.selectors';
import { setShowRedisStackCongratulations } from '../../../../../store/databases/databases.actions';
import { buildPath, routes } from '../../../../../utils/constants/routes';
import useNewRedisOptInCloudRegionSelectionProps from '../FreeDbCloudVendor/hooks/useNewRedisOptInCloudRegionSelectionProps';
import FreeDbBanner from './components/FreeDbBanner';
import useFreeDbForm from './hooks/useFreeDbForm';

const FreeDbForm = ({ setShowLoader }: FreeDbFormProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newCustomer.freeDB;
  const history = useHistory();
  const navigateToNewSubscription = useNavigateToNewSubscription();
  const dispatch = useDispatch();
  const productType = useSelector(productTypeSelector);
  const userId = useSelector(authIdSelector);
  const usersQuery = usersQueries.useUser(Number(userId));
  const { setIsFirstLogin } = useNewCustomerLoginStore();

  const {
    freePlans,
    vendorRegionPlanMapper,
    selectedRegion,
    selectedVendor,
    setSelectedRegion,
    setSelectedVendor,
    ...newRedisOptInProps
  } = useNewRedisOptInCloudRegionSelectionProps();

  const { createFreeDb } = useFreeDbForm({
    freePlans,
    vendorRegionPlanMapper,
    selectedRegion,
    selectedVendor
  });

  const handleFreeSubError = () => {
    setShowLoader(false);
    setIsFirstLogin(false);
    history.push(routes.createSubscription.essential);
  };

  const handleFreeDbError = (subscriptionId: number) => {
    setShowLoader(false);
    setIsFirstLogin(false);
    history.push(buildPath(routes.subscriptions.subscription.db.createBdb, { subscriptionId }));
  };

  const handleFreeDbSuccess = (bdb: Bdb) => {
    if (bdb?.is_redis_stack) {
      dispatch(setShowRedisStackCongratulations(true));
    }

    setShowLoader(false);
    setIsFirstLogin(false);
    history.push(
      buildPath(routes.subscriptions.subscription.db.root, {
        subscriptionId: bdb.subscription
      })
    );
  };

  const handleOnClickCreateFreeDatabase = () => {
    createFreeDb({ handleFreeSubError, handleFreeDbError, handleFreeDbSuccess });
    setShowLoader(true);
  };

  const handleOnClickCreateCustomDatabase = () => {
    setIsFirstLogin(false);
    analytics.ga.track(trackSignUp('sign-up', 'welcome-create', productType));
    navigateToNewSubscription();
  };

  return (
    <>
      <S.Header>
        <S.SubTitle size="M">
          {t(keyPrefix.welcome, { username: usersQuery.data.first_name })}
        </S.SubTitle>
        <S.Title size="XL">{t(keyPrefix.title)}</S.Title>
      </S.Header>

      <S.VendorSection>
        <S.VendorContainer data-testid="free-db-cloud-vendor">
          <FreeDbCloudVendor
            freePlans={freePlans}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            vendorRegionPlanMapper={vendorRegionPlanMapper}
            {...newRedisOptInProps}
          />
        </S.VendorContainer>
      </S.VendorSection>

      <S.Banner>
        <FreeDbBanner />
      </S.Banner>

      <S.Cta>
        <S.ButtonsContainer>
          <TextButton
            data-testid="free-db--button-create-custom-database"
            onClick={handleOnClickCreateCustomDatabase}
          >
            {t(keyPrefix.buttonCreateCustomDatabase)}
          </TextButton>

          <Button
            variant="primary"
            size="medium"
            data-testid="free-db--button-create-free-database"
            onClick={handleOnClickCreateFreeDatabase}
          >
            {t(keyPrefix.buttonCreateFreeDatabase)}
          </Button>
        </S.ButtonsContainer>
      </S.Cta>
    </>
  );
};

export default FreeDbForm;
