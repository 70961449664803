import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './DontHaveAnAccount.style';
import { routes } from '../../../../../../utils/constants/routes';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';
import { i18translation } from '../../../../../../locale/translations/i18NsPaths';

const DontHaveAnAccount = () => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;

  return (
    <S.DontHaveAnAccountText>
      {t(keyPrefix.dontHaveAccount)}

      {systemParams?.signup_main_page ? (
        <a data-testid="link--sign-up" href={systemParams.signup_main_page}>
          {t(keyPrefix.signUp)}
        </a>
      ) : (
        <Link data-testid="link--sign-up" to={routes.signUp}>
          {t(keyPrefix.signUp)}
        </Link>
      )}
    </S.DontHaveAnAccountText>
  );
};

export default DontHaveAnAccount;
