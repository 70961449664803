import redisLogo from '../../assets/icons/RedisWhiteLogo.svg';
import signUpInShapesBg from '../../assets/icons/SignUpInShapesBg.svg';
import * as S from './Login.style';

const LoginLogoSide = () => (
  <S.LogoSide>
    <img src={redisLogo} alt="Redis" />
    <img src={signUpInShapesBg} alt="sign up shapes" />
  </S.LogoSide>
);

export default LoginLogoSide;
