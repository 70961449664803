import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { okStatus } from '../../../../utils/constants/api/statuses';
import { ActivateInvitationCallbackRequestProps } from '../ResetPassword.types';
import { MAX_PASSWORD_FIELD_VALUE, MIN_PASSWORD_FIELD_VALUE } from '../../utils/constants';
import { passwordRegex } from '../../../../utils/validations/formRegexes';
import { i18translation } from '../../../../locale/translations/i18NsPaths';

export const activateInvitationCallbackRequest = async ({
  search,
  successCallback,
  errorCallback
}: ActivateInvitationCallbackRequestProps) => {
  try {
    const { status, data } = await RedisApiAxiosInstance.get(
      `${ApiPaths.USERS}/invitation_callback/${search}`
    );

    if (status === okStatus) {
      const { activation_invite_key, user_account_id } = data; // eslint-disable-line

      successCallback({ key: activation_invite_key, accId: user_account_id });
    }
  } catch (e) {
    errorCallback(e);
    console.error(e, 'Error on activateInvitationCallbackRequest'); // eslint-disable-line
  }
};

export const buildValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t(i18translation.LoginScreen.resetPasswordScreen.required))
      .min(
        MIN_PASSWORD_FIELD_VALUE,
        t(i18translation.LoginScreen.resetPasswordScreen.passwordMin, {
          minValue: MIN_PASSWORD_FIELD_VALUE
        })
      )
      .matches(passwordRegex, t(i18translation.LoginScreen.resetPasswordScreen.passwordRegex))
      .max(
        MAX_PASSWORD_FIELD_VALUE,
        t(i18translation.LoginScreen.resetPasswordScreen.passwordMAx, {
          minValue: MAX_PASSWORD_FIELD_VALUE
        })
      ),
    confirm_password: Yup.string().when('password', {
      is: (value) => value,
      then: Yup.string()
        .required(t(i18translation.LoginScreen.resetPasswordScreen.required))
        .oneOf(
          [Yup.ref('password'), null],
          t(i18translation.LoginScreen.resetPasswordScreen.passwordMatch)
        )
    })
  });
