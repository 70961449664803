import styled from 'styled-components/macro';

export const ChipList = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
`;

export const HiddenListNumberWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.lightBlue4};
  border-radius: 4px;
  height: 2.8rem;
  padding: 0.4rem 0.7rem;
  margin-left: 0.5rem;
  display: inline-flex;
`;

export const Text = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.blue9};
  line-height: 1.9rem;
`;
