import { Modal } from '@redislabsdev/redis-ui-components';
import { useState } from 'react';
import AppAuthFactorForm from './components/AppAuthFactorForm/AppAuthFactorForm';
import SmsFactorForm from './components/SmsFactorForm/SmsFactorForm';
import useLogOut from '../../../../hooks/useLogOut';

type MfaModalTypes = 'smsFactor' | 'totpFactor';

interface Props {
  isBothMfaMethodsExists: boolean;
}

const MfaLoginModal = ({ isBothMfaMethodsExists }: Props) => {
  const [modalToDisplay, setModalToDisplay] = useState<MfaModalTypes>(
    isBothMfaMethodsExists ? 'totpFactor' : 'smsFactor'
  );

  const { performLogout } = useLogOut();
  const switchToTotFactor = () => setModalToDisplay('totpFactor');
  const switchToSmsFactor = () => setModalToDisplay('smsFactor');

  return (
    <Modal.Compose open>
      <Modal.Content.Compose style={{ width: '50rem' }}>
        <Modal.Content.Close
          onClick={() => {
            performLogout();
          }}
        />
        {modalToDisplay === 'smsFactor' ? (
          <SmsFactorForm switchToAuthAppModal={switchToTotFactor} />
        ) : (
          <AppAuthFactorForm switchToSmsModal={switchToSmsFactor} />
        )}
      </Modal.Content.Compose>
    </Modal.Compose>
  );
};

export default MfaLoginModal;
