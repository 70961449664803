import { call } from 'redux-saga/effects';
import { mwSubscriptionApi } from 'services/api/resources/mwSubscription/mwSubscription.resource';
import i18n from 'locale/i18n';
import { i18translation } from 'locale/translations/i18NsPaths';
import { showToast } from '@redislabsdev/redislabs-ui-components';

export function* insertMaintenanceWindow(
  subscriptionId: number,
  maintenance_window?: MwSubscriptionUpdateData
) {
  try {
    yield call(mwSubscriptionApi.update, {
      id: subscriptionId,
      maintenanceWindow: maintenance_window
    });
  } catch (e) {
    const maintenanceWindowMessage = i18n.t(i18translation.maintenanceWindow.errors.update);
    showToast(maintenanceWindowMessage);
  }
}
