import { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/constants/routes';
import { useSelector } from 'react-redux';
import { Config } from 'config';
import { subscriptionsQueries } from 'queryClient/subscriptions/subscriptions.queries';
import { createMFEWrapper } from '../../../components/MFEWrapper/MFEWrapper';
import { useNewCustomerLoginStore } from '../store/useNewCustomerLoginStore';
import { usersMutations } from '../../../queryClient/users/users.mutations';
import { authIdSelector } from '../../../store/auth/auth.selectors';
import { usersQueries } from '../../../queryClient/users/users.queries';
import useNavigateToNewSubscription from '../../../hooks/useNavigateToNewSubscription';
import { useConnectionWizardClientStore } from '../../../components/ConnectionWizard/components/ConnectionWizardClient/store/useConnectionWizardClientStore';
import { OnboardingProps } from './OnboardingMFE.types';
import RedisApiAxiosInstance from '../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

const OnboardingMFEComponent = createMFEWrapper<OnboardingProps>(
  // @ts-ignore
  lazy(() => import('kyc/Onboarding')),
  'mfe'
);

const OnboardingMFE = () => {
  const navigateToNewSubscription = useNavigateToNewSubscription();
  const history = useHistory();
  const connectionWizardClientStore = useConnectionWizardClientStore();
  const { setIsFirstLogin } = useNewCustomerLoginStore();
  const userId = useSelector(authIdSelector);
  const usersMutation = usersMutations.useUpdateUser(Number(userId));
  const { data: user } = usersQueries.useUser(Number(userId));
  const subscriptionsQuery = subscriptionsQueries.useSubscriptions();
  const isSubscriptionsEmpty = (subscriptionsQuery?.data?.subscriptions || []).length === 0;

  const updateUserData = (role: string) => {
    const regexp = /[^A-Za-zÀ-ÖØ-öø-ÿ-'.]/g;
    user.first_name = user.first_name.replace(regexp, ' ');
    user.last_name = user.last_name.replace(regexp, ' ');

    usersMutation.mutate({ ...user, title: role });
  };

  const onClickFreeDatabase = () => {
    setIsFirstLogin(false);
    history.push(routes.newCustomer.freeDb);
  };

  const onClickContinue = () => {
    setIsFirstLogin(false);
    history.push(routes.subscriptions.root);
  };

  const onClickCustomDatabase = () => {
    setIsFirstLogin(false);
    navigateToNewSubscription();
  };

  const onClickSetupFixedSubscription = () => {
    setIsFirstLogin(false);
    history.push(routes.createSubscription.essential);
  };

  const onClickSetupFlexibleSubscription = () => {
    setIsFirstLogin(false);
    history.push(routes.createSubscription.pro.create);
  };

  const onSelectedClient = (clientName: string) => {
    connectionWizardClientStore.setData(clientName);
  };

  // TODO: Remove '/api' from Config.CURRENT_ORIGINS.KYC when moving to MFE and remove the string replace below
  const basePath = Config.CURRENT_ORIGINS.KYC.replace('/api', '');

  return (
    <OnboardingMFEComponent
      {...{
        onClickCustomDatabase,
        onClickFreeDatabase,
        onClickSetupFixedSubscription,
        onClickSetupFlexibleSubscription,
        onSelectedClient,
        onClickContinue,
        updateUserData,
        axiosInstance: RedisApiAxiosInstance,
        basePath,
        isSubscriptionsLoading: subscriptionsQuery.isInitialLoading,
        isSubscriptionsEmpty
      }}
    />
  );
};

export default OnboardingMFE;
