import { Route, Switch } from 'react-router-dom';
import ForgotPasswordForm from './components/ForgotPasswordForm/ForgotPasswordForm';
import { routes } from '../../utils/constants/routes';
import SignInForm from './components/SignInForm/SignInForm';
import SsoLoginForm from './components/SsoLoginForm/SsoLoginForm';
import ResetPassword from './components/ResetPassword/ResetPassword';
import SignInFromUserInvitation from './components/SignInFromUserInvitation/SignInFromUserInvitation';
import SignUp from '../Login/SignUp/SignUp';
import UserActivation from './components/UserActivation/UserActivation';

const LoginAuthRoutes = () => {
  return (
    <Switch>
      <Route exact path={routes.login} component={SignInForm} />
      <Route exact path={routes.forgotPassword} component={ForgotPasswordForm} />
      <Route exact path={routes.saml} component={SsoLoginForm} />
      <Route exact path={routes.resetPassword} component={SignInFromUserInvitation} />
      <Route exact path={routes.activateResetPassword} component={ResetPassword} />
      <Route exact path={routes.activateRegistration} component={UserActivation} />
      {/* this signup is only used in qa, we need to understand if it can be deleted */}
      <Route exact path={routes.signUp} component={SignUp} />

      <Route component={SignInForm} />
    </Switch>
  );
};

export default LoginAuthRoutes;
