import React from 'react';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import * as S from './HiddenListNumber.style';
import { IPlainObject } from '../../types/interfaces';

interface IProps {
  hiddenList: string[];
  hiddenListMap?: IPlainObject;
  className?: string;
}

const HiddenListNumber: React.FC<IProps> = ({ hiddenList, hiddenListMap, className }) => (
  <Tooltip
    textColor="darkBlue1"
    wrapperId="hidden_number"
    delayHide={100}
    zIndex={6}
    tooltipContent={
      <S.HoverText data-testid="hidden-list-items">
        {hiddenList.map((item, index) => (
          <div key={index}>{hiddenListMap ? hiddenListMap[item] : item}</div> // eslint-disable-line
        ))}
      </S.HoverText>
    }
  >
    <S.ItemsLengthText className={className}>{`+${hiddenList.length}`}</S.ItemsLengthText>
  </Tooltip>
);

export default HiddenListNumber;
