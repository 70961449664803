import { showToast } from '@redislabsdev/redislabs-ui-components';
import { FC } from 'react';
import { isEmpty } from 'lodash';
import { parametersQueries } from '../../queryClient/parameters/parameters.queries';
import useServices from '../../services/hooks/useServices';
import { Loader } from '../Login/Login.style';

export const LoadSystemParameters: FC = ({ children }) => {
  const paramsQuery = parametersQueries.useSystemParameters();

  useServices(paramsQuery.systemParams);

  if (paramsQuery.isError || (paramsQuery.isSuccess && isEmpty(paramsQuery.systemParams))) {
    showToast('We encountered a problem. Please try to refresh the browser or come again later');

    return <Loader dataTestId="loader-load-load-system-params" />;
  }

  if (paramsQuery.isInitialLoading) return <Loader dataTestId="loader-load-load-system-params" />;

  return <>{children}</>;
};
