import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import { useTranslation } from 'react-i18next';
import { i18translation } from '../../../../locale/translations/i18NsPaths';
import UserFormInfo from '../UserFormInfo/UserFormInfo';
import { login } from '../../../../store/auth/auth.action';
import { routes } from '../../../../utils/constants/routes';
import * as S from './UserActivation.style';
import { badRequestStatus } from '../../../../utils/constants/api/statuses';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import { usersQueries } from '../../../../queryClient/users/users.queries';
import { errorMap } from './utils/UserActivation.constants';
import { usersMutations } from '../../../../queryClient/users/users.mutations';
import { useLoginContext } from '../../Login.context';
import { getQueryParam } from '../../../../utils/getQueryParam/getQueryParam';

const UserActivation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const [errorCodeString, setErrorCodeString] = useState('');
  const [activationLinkSent, setActivationLinkSent] = useState(false);
  const newCustomerLoginStore = useNewCustomerLoginStore();
  const { mutate } = usersMutations.useResendUserActivationLink();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;
  const showLoaderContext = useLoginContext();

  useEffect(() => {
    showLoaderContext.setShowLoader(true);
  }, []);

  usersQueries.useRegistrationCallback(search, {
    onSuccess: () => {
      // Set localstorage with data for next page - onboarding
      window.localStorage.setItem('rcPlan', getQueryParam('rcplan'));
      dispatch(login({ callCsrfFlag: true }));
      window.localStorage.removeItem('onboarding');
      newCustomerLoginStore.setIsFirstLogin(true);
    },
    onError: (err) => {
      errorCallback(err);
    },
    onSettled: () => {
      showLoaderContext.setShowLoader(false);
    }
  });

  const errorCallback = (errorCode) => {
    if (errorCode.response.status === badRequestStatus) {
      setErrorCodeString(errorCode.response.data?.errors?.code);
    } else {
      extractErrorAndShowToast(errorCode);
      history.push(routes.root);
    }
  };
  const sendNewActivationLink = () => {
    mutate(search, {
      onSuccess: () => {
        setActivationLinkSent(true);
      },
      onError: (err) => {
        errorCallback(err);
      }
    });
  };

  if (activationLinkSent) {
    return (
      <UserFormInfo
        title={t(keyPrefix.thanksForSignUp.thanksTitle)}
        subTitle={t(keyPrefix.thanksForSignUp.checkEmail)}
        text={t(keyPrefix.thanksForSignUp.checkEmailActivationMessage)}
        showBackToLoginButton={false}
      />
    );
  }

  if (errorCodeString === errorMap.alreadyActivated) {
    return <UserFormInfo title={t(keyPrefix.accountAlreadyActivated)} />;
  }

  if (errorCodeString === errorMap.keyExpired) {
    return (
      <UserFormInfo title={t(keyPrefix.userActivationLinkExpired)} showBackToLoginButton={false}>
        <S.SendNewActivationLinkBtn onClick={sendNewActivationLink}>
          {t(keyPrefix.sendNewActivationBtn)}
        </S.SendNewActivationLinkBtn>
      </UserFormInfo>
    );
  }

  return <></>;
};

export default UserActivation;
