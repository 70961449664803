import { FC } from 'react';
import { IconProps, ToastCheckIcon } from '@redislabsdev/redis-ui-icons';
import { useTheme } from 'styled-components/macro';
import * as S from './IconCard.style';

interface IconCardProps {
  isSelected: boolean;
  onSelect: () => void;
  icon: (props: IconProps) => JSX.Element;
  cardUnderText: string;
  testId?: string;
  disabled?: boolean;
  disabledText?: string;
  role?: string;
  underTextTestId?: string;
  linkUrl?: string;
  linkText?: string;
}

const IconCard: FC<IconCardProps> = ({
  isSelected,
  onSelect,
  icon: Icon,
  cardUnderText,
  testId = 'icon-card',
  disabled,
  disabledText,
  role,
  underTextTestId,
  linkUrl,
  linkText
}) => {
  const theme = useTheme();

  const onClick = () => {
    if (!linkUrl) {
      onSelect();
    }
  };

  return (
    <S.CardWrapper
      data-testid={testId}
      aria-selected={isSelected}
      disabled={disabled}
      data-role={role}
    >
      <S.WhiteCircle selected={isSelected}>
        <S.ActiveCardIcon selected={isSelected} data-testid="active-card-check-icon">
          <ToastCheckIcon size="XL" color={theme.colors.green1} />
        </S.ActiveCardIcon>
      </S.WhiteCircle>
      <S.Card role="card" selected={isSelected} onClick={onClick}>
        <Icon customSize="5rem" />
        {disabled && <S.DisabledText>{disabledText}</S.DisabledText>}
        {linkUrl && (
          <S.LinkWrapper href={linkUrl} target="_blank">
            <S.LinkUnderlay />
            <S.LinkText>{linkText}</S.LinkText>
            <S.LinkIconBackground />
            <S.LinkIcon size="S" color="white" />
          </S.LinkWrapper>
        )}
      </S.Card>
      <S.CardUnderText data-testid={underTextTestId}>{cardUnderText}</S.CardUnderText>
    </S.CardWrapper>
  );
};

export default IconCard;
