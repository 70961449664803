import styled from 'styled-components/macro';

export const FreeDBLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  height: 100%;
`;
