import { PropsWithChildren } from 'react';
import redisLogo from 'assets/icons/RedisBlackLogo.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@redislabsdev/redis-ui-components';
import * as S from './ValidateCookiesEnabled.style';
import { i18translation } from '../../locale/translations/i18NsPaths';

const ValidateCookiesEnabled = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.cookiesRequired;
  if (!window.navigator.cookieEnabled) {
    return (
      <S.Wrapper>
        <S.ContentContainer>
          <S.RedisLogoImg src={redisLogo} alt="Redis" />
          <S.HeadingText>{t(keyPrefix.title)}</S.HeadingText>
          <S.ExplanationText>{t(keyPrefix.description1)}</S.ExplanationText>
          <S.ExplanationText>{t(keyPrefix.description2)}</S.ExplanationText>

          <S.Footer>
            <Button size="large" onClick={() => window.location.reload()}>
              {t(keyPrefix.actionBtnText)}
            </Button>
          </S.Footer>
        </S.ContentContainer>
      </S.Wrapper>
    );
  }

  return <>{children}</>;
};

export default ValidateCookiesEnabled;
