import { Input } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Button, Modal, TextButton } from '@redislabsdev/redis-ui-components';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as S from './AppAutFactorForm.style';
import { mfaPayloadSelector } from '../../../../../../store/auth/auth.selectors';
import useFactorChange from '../../hooks/useFactorChange';
import useMfaForm from '../../hooks/useMfaForm';

interface Props {
  switchToSmsModal: () => void;
}

const AppAuthFactorForm = ({ switchToSmsModal }: Props) => {
  const { t } = useTranslation();
  const { totpFactorAvailable } = useSelector(mfaPayloadSelector);
  const status = useFactorChange('Totp');
  const keyPrefix = i18translation.LoginScreen.mfaLogin.authAppModal;
  const { handleAuthFactorSubmit } = useMfaForm();
  const handleSubmit = ({ authCode }: { authCode: string }) => {
    handleAuthFactorSubmit('Totp', authCode);
  };

  return (
    <Formik initialValues={{ authCode: '' }} onSubmit={handleSubmit}>
      {({ handleChange, dirty }) => (
        <Form>
          <Modal.Content.Header title={t(keyPrefix.title)} />
          <Modal.Content.Body.Compose>
            <S.InputHint>{t(keyPrefix.codeIsRequired)}</S.InputHint>
            <Input id="auth-app-code" name="authCode" onChange={handleChange} width="100%" />
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer.Compose>
            <S.ButtonContainer>
              <TextButton data-testid="button--text-me-code" onClick={switchToSmsModal}>
                {t(keyPrefix.textMeCode)}
              </TextButton>
              <Button
                type="submit"
                disabled={!dirty || (totpFactorAvailable && status !== 'success')}
              >
                {t('LoginScreen.mfaLogin.verify')}
              </Button>
            </S.ButtonContainer>
          </Modal.Content.Footer.Compose>
        </Form>
      )}
    </Formik>
  );
};

export default AppAuthFactorForm;
