import { Input } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { TextButton } from '@redislabsdev/redis-ui-components';
import MfaModalWrapper from '../MfaModalWrapper/MfaModalWrapper';
import * as S from '../../MfaLogin.styles';

interface Props {
  switchToSmsModal: () => void;
}

const AppAuthModal = ({ switchToSmsModal }: Props) => {
  const { t } = useTranslation();

  const keyPrefix = i18translation.LoginScreen.mfaLogin.authAppModal;

  const SmsModalBtn = (
    <TextButton data-testid="button--text-me-code" onClick={switchToSmsModal}>
      {t(keyPrefix.textMeCode)}
    </TextButton>
  );

  return (
    <MfaModalWrapper title={t(keyPrefix.title)} bottomLeftComponent={SmsModalBtn} mfaType="Totp">
      {(onChange, value) => {
        return (
          <>
            <S.InputHint htmlFor="auth-app-code">{t(keyPrefix.codeIsRequired)}</S.InputHint>
            <Input
              id="auth-app-code"
              name="auth-app-code"
              onChange={onChange}
              value={value}
              width="100%"
            />
          </>
        );
      }}
    </MfaModalWrapper>
  );
};

export default AppAuthModal;
