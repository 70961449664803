import reactStringReplace from 'react-string-replace';
import { cloneDeep } from 'lodash';
import { UseComboboxStateChange } from 'downshift';
import { MultiSelectOption } from 'components/Shared/MultiSelectDropDown/MultiSelectDropDown.types';
import { useState } from 'react';
import * as S from './TemplateSelect.style';
import { DropDownItemsType, TemplateSelectProps } from './TemplateSelect.types';
import { getDropDownItemsFromTemplate, getSelectedKeys } from './utils/templateSelectUtils';

const TemplateSelect = ({ template, onChange, ...rest }: TemplateSelectProps) => {
  const [dropdownItems, setDropDownItems] = useState<DropDownItemsType>(() =>
    getDropDownItemsFromTemplate(template)
  );

  const handleOnChange = (dropDownItems: DropDownItemsType) => {
    const selectedKeys = getSelectedKeys(dropDownItems);
    onChange(selectedKeys);
  };

  const getDropDownSingleSelection = (key: string, matchIndex: number) => {
    return (
      <S.DropDownSingleSelection
        variant="underline"
        placeholder={dropdownItems[key].label}
        key={matchIndex}
        items={dropdownItems[key].items}
        renderItem={(item: MultiSelectOption) => <S.DropDownItem>{item.label}</S.DropDownItem>}
        selectedItem={dropdownItems[key].items.find((item) => item.selected)}
        onSelectedItemChange={(changes: UseComboboxStateChange<MultiSelectOption>) => {
          const newItems = cloneDeep(dropdownItems);

          newItems[key].items.forEach((item, index) => {
            newItems[key].items[index].selected = item.id === changes.selectedItem.id;
          });

          setDropDownItems(newItems);
          handleOnChange(newItems);
        }}
        dataTestIdSuffix={key}
      />
    );
  };

  const getDropDownMultiSelection = (key: string, matchIndex: number) => {
    return (
      <S.DropDownMultiSelection
        key={matchIndex}
        label={dropdownItems[key].label}
        options={dropdownItems[key].items}
        toggleSelected={(selectedId) => {
          const newItems = cloneDeep(dropdownItems);

          newItems[key].items[selectedId].selected = !newItems[key].items[selectedId].selected;

          setDropDownItems(newItems);
          handleOnChange(newItems);
        }}
        renderItem={(item: MultiSelectOption) => <S.DropDownItem>{item.label}</S.DropDownItem>}
        withCheckbox
        disableSearch
        shouldAllowDeletion={false}
        testId={`selectbox--${key}`}
      />
    );
  };

  return (
    <S.Container data-testid={rest['data-testid'] || 'template-select'}>
      {reactStringReplace(template?.sentence, /(\{.*?\})/g, (match, matchIndex) => {
        const key = match.replace(/[{}]/g, '');

        if (template.options[key].type === 'single-select') {
          return getDropDownSingleSelection(key, matchIndex);
        }

        if (template.options[key].type === 'multi-select') {
          return getDropDownMultiSelection(key, matchIndex);
        }

        return null;
      })}
    </S.Container>
  );
};

export default TemplateSelect;
