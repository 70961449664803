import { AxiosError } from 'axios';
import { subscriptionsApi } from 'services/api/resources/subscriptions/subscriptions.resource';
import { UseQueryOptions } from '@tanstack/react-query';
import subscriptionsKeys from './subscriptions.keys';
import useSmQuery from '../useSmQuery';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';

interface UseSubscriptionsQueryProps {
  params?: GetSubscriptionParams;
  options?: UseQueryOptions<GetSubscriptionsResponse>;
}

const useSubscriptionsQuery = ({
  params = { productType: 'unifiedrc', includeNextPaymentDate: false },
  options = {}
}: UseSubscriptionsQueryProps = {}) => {
  const queryFn = async () => {
    const { data } = await subscriptionsApi.getAll(params);

    return data;
  };

  return useSmQuery({
    permissionKey: 'subscription_get_subscriptions',
    queryKey: subscriptionsKeys.getAll,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: (e: AxiosError) => {
      extractErrorAndShowToast(e);
    },
    ...options
  });
};

export const subscriptionsQueries = {
  useSubscriptions: useSubscriptionsQuery
};
