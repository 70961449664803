import { errorsMap, GENERAL_ERROR_CODE, ErrorsMapCodes } from '../constants/api/errorsMap';
import { ErrorInfo, ExtendedAxiosError } from '../../types/interfaces';
import { confirmationDialogContextValue } from './confirmationDialogContextValue';
import i18n from '../../locale/i18n';
import { showNotification } from '../../components/Notification/Notification';
import { NotificationButtonProps } from '../../components/Notification/Notification.types';

export const extractError = (data): ErrorInfo => {
  const code = extractErrorCode(data);

  return errorsMap[code] ? errorsMap[code] : errorsMap[GENERAL_ERROR_CODE];
};

export const extractErrorCode = (data): ErrorsMapCodes => {
  let code;

  if (data?.errors && data?.errors?.code) {
    code = data.errors.code;
  } else if (data?.errors) {
    const errors = typeof data.errors === 'string' ? JSON.parse(data.errors) : data.errors;
    if (Array.isArray(errors) && errors[0]?.error_code) {
      code = errors[0].error_code;
    } else {
      code = GENERAL_ERROR_CODE;
    }
  } else {
    code = GENERAL_ERROR_CODE;
  }

  return code;
};

export const extractErrors = (data): ErrorInfo[] => {
  let errorInfo;

  if (data?.errors && data?.errors?.code) {
    errorInfo = [errorsMap[GENERAL_ERROR_CODE]];
  } else if (data?.errors) {
    const errors = typeof data.errors === 'string' ? JSON.parse(data.errors) : data.errors;
    if (Array.isArray(errors) && errors[0]?.error_code) {
      errorInfo = errors.map((error) => errorsMap[error.error_code]);
    } else {
      errorInfo = [errorsMap[GENERAL_ERROR_CODE]];
    }
  } else {
    errorInfo = [errorsMap[GENERAL_ERROR_CODE]];
  }

  return errorInfo;
};

export const handleErrorDisplay = (
  e: ExtendedAxiosError,
  notificationButtonProps?: NotificationButtonProps
) => {
  const modal = document.querySelectorAll('[data-role="dialog-hub"]');
  const { message } = extractError(e?.response?.data);
  const errMessage = message || '';

  if (modal.length > 0) {
    confirmationDialogContextValue.values?.setIndicator({
      text: i18n.t(errMessage),
      type: 'warning'
    });
  } else {
    showNotification({ body: i18n.t(message), buttonProps: notificationButtonProps }, 'error');
  }

  return errMessage;
};
