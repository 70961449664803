import { i18translation } from 'locale/translations/i18NsPaths';
import { ErrorInfo } from '../../../types/interfaces';
import { getCrdbFieldPath } from '../../../screens/SubscriptionsAndDatabases/components/ViewCrdb/components/CrdbConfiguration/utils/crdbConfigurationFieldGetters';

const createErrorsMap = <T extends { [name: string]: ErrorInfo }>(map: T) => map;
export const GENERAL_ERROR_CODE = 'general-error';

export const errorsMap = createErrorsMap({
  'bdb-max-number-bdbs-exceed': {
    message: 'createUpdateDBForm.validations.maxDbNumberExceeded'
  },
  // Name
  'bdb-name-already-exist': {
    message: 'createUpdateDBForm.validations.nameAlreadyExist',
    field: 'name'
  },
  'bdb-name-does-not-match-pattern': {
    message: 'createUpdateDBForm.validations.invalidName'
  },
  'bdb-name-is-blank': {
    message: 'createUpdateDBForm.validations.nameAlreadyExist',
    field: 'name'
  },
  // Custom bdb port
  'custom-port-is-not-supported': {
    message: 'httpErrorsMap.customPortNotSupported',
    field: 'port'
  },
  'custom-port-is-in-use': {
    message: 'httpErrorsMap.portAlreadyInUse',
    field: 'port'
  },
  // Memory
  'bdb-usage-exceeds-global-limit': {
    message: 'createUpdateDBForm.validations.bdbUsageExceedsGlobalLimit',
    field: 'memoryLimit'
  },
  'bdb-usage-exceeds-bdb-limit': {
    message: 'createUpdateDBForm.validations.bdbUsageExceedsBdbLimit',
    field: 'memoryLimit'
  },
  'bdb-memory-limit-is-smaller-than-actual': {
    message: 'createUpdateDBForm.validations.bdbMemoryLimitIsSmallerThanActual',
    field: 'memoryLimit'
  },
  memory_full: {
    message: 'Cluster memory is fully allocated',
    field: 'memory_size'
  },
  client_ssl_cert_invalid_x509: {
    message: 'Certificate must comply with X.509 specification.',
    field: 'authentication_ssl_client_cert'
  },
  // Backup
  'validate-backup-invalid-pattern': {
    message: 'createUpdateDBForm.validations.backupInvalidPattern',
    field: 'backupPath'
  },
  'validate-backup-invalid-ec2-case': {
    message: 'createUpdateDBForm.validations.backupInvalidEc2Case',
    field: 'backupPath'
  },
  'validate-backup-aws': {
    message: 'createUpdateDBForm.validations.backupAwsGeneral',
    field: 'backupPath'
  },
  'validate-backup-aws-write': {
    message: 'createUpdateDBForm.validations.backupAwsWritePermission',
    field: 'backupPath'
  },
  'validate-backup-aws-general': {
    message: 'createUpdateDBForm.validations.backupAwsGeneral',
    field: 'backupPath'
  },
  'validate-backup-gce': {
    message: 'createUpdateDBForm.validations.backupGce',
    field: 'backupPath'
  },
  'validate-backup-abs': {
    message: 'createUpdateDBForm.validations.backupAbs',
    field: 'backupPath'
  },
  'validate-backup-ftp-delete': {
    message: 'createUpdateDBForm.validations.backupFtpDeletePermission',
    field: 'backupPath'
  },
  'validate-backup-ftp-write': {
    message: 'createUpdateDBForm.validations.backupFtpWritePermission',
    field: 'backupPath'
  },
  'validate-backup-ftp-not-found': {
    message: 'createUpdateDBForm.validations.backupFtpNotFound',
    field: 'backupPath'
  },
  'validate-backup-ftp-login': {
    message: 'createUpdateDBForm.validations.backupFtpLogin',
    field: 'backupPath'
  },
  'validate-backup-ftp-connect': {
    message: 'createUpdateDBForm.validations.backupFtpConnect',
    field: 'backupPath'
  },
  'validate-backup-ftp-io': {
    message: 'createUpdateDBForm.validations.backupFtpIo',
    field: 'backupPath'
  },
  'validate-backup-cross-account-usage': {
    message: 'createUpdateDBForm.validations.backupCrossAccountUsage',
    field: 'backupPath'
  },
  'validate-backup-general': {
    message: 'createUpdateDBForm.validations.backupGeneral',
    field: 'backupPath'
  },
  // backup related errors
  'backup-general': {
    message: 'Backup Database failed.'
  },
  'bdb-cant-initiate-backup-on-inactive-bdb': {
    message: 'Cannot initiate backup on inactive database. Please try again later.'
  },
  'bdb-cant-initiate-backup': {
    message: 'Unable to access backup server. Please check path.'
  },
  // Import related errors
  import_general: {
    message: 'Unable to access import server. Please check path/s.',
    field: 'urls'
  },
  import_validation: {
    message: 'Unable to access import server. Please check path/s.',
    field: 'urls'
  },
  'bdb-cant-initiate-import': {
    message: 'File import error. Could not initiate import for bdb. Please check path.',
    field: 'urls'
  },
  'bdb-import-source-general-error': {
    message: 'databaseConfigTab.importDatabase.errorMessages.generalErrorResponse'
  },
  'bdb-import-source-no-such-bucket': {
    message: 'databaseConfigTab.importDatabase.errorMessages.noSuchBuckerResponse'
  },
  'bdb-import-source-wrong-size': {
    message: 'databaseConfigTab.importDatabase.errorMessages.wrongSizeResponse'
  },
  'bdb-import-source-redislabs-bucket': {
    message: 'databaseConfigTab.importDatabase.errorMessages.redisBucketErrorResponse'
  },
  'bdb-is-not-ready': {
    message: 'databaseConfigTab.importDatabase.errorMessages.bdbNotReady'
  },
  // Clustering
  'bdb-regex-rule-empty': {
    message: 'createUpdateDBForm.validations.regexRuleEmpty',
    field: 'pattern'
  },
  'bdb-regex-rule-exist': {
    message: 'createUpdateDBForm.validations.regexRuleExist',
    field: 'pattern'
  },
  'bdb-regex-rule-invalid': {
    message: 'createUpdateDBForm.validations.regexRuleInvalid',
    field: 'pattern'
  },
  'bdb-regex-rule-missing-group-tag': {
    message: 'createUpdateDBForm.validations.regexRuleMissingGroupTag',
    field: 'pattern'
  },
  'bdb-regex-rule-too-many-tags': {
    message: 'createUpdateDBForm.validations.regexRuleTooManyTags',
    field: 'pattern'
  },
  // Access control and security
  'bdb-invalid-source-ips': {
    message: 'createUpdateDBForm.validations.sipInvalid',
    field: 'addr'
  },
  'bdb-sip-size-over-limit': {
    message: 'createUpdateDBForm.validations.sipSizeOverLimit'
  },
  'bdb-sg-size-over-limit': {
    message: 'createUpdateDBForm.validations.sgSizeOverLimit'
  },
  'bdb-redis-password-is-not-allowed': {
    message: 'createUpdateDBForm.validations.redisPasswordNotAllowed'
  },
  'bdb-redis-password-is-missing': {
    message: 'createUpdateDBForm.validations.redisPasswordMissing'
  },
  'bdb-redis-password-too-long': {
    message: 'createUpdateDBForm.validations.redisPasswordTooLong'
  },
  // Replica-of
  'bdb-replica-general-error': {
    message: 'createUpdateDBForm.validations.replicaOfGeneralError'
  },
  'bdb-replica-of-not-supported': {
    message: 'createUpdateDBForm.validations.replicaOfNotSupported'
  },
  'bdb-replica-of-cidr-overlap': {
    message: 'createUpdateDBForm.validations.replicaOfCidrOverlap',
    field: 'source'
  },
  'bdb-invalid-sync-source': {
    message: 'createUpdateDBForm.validations.replicaOfInvalidSyncSource',
    field: 'source'
  },
  'bdb-replica-connection-error': {
    message: 'createUpdateDBForm.validations.replicaOfConnectionError'
  },
  'bdb-sync-sources-size-exceed': {
    message: 'createUpdateDBForm.validations.syncSourcesSizeExceed'
  },
  'bdb-sync-source-cycled': {
    message: 'createUpdateDBForm.validations.syncSourcesCycled',
    field: 'source'
  },
  'bdb-replicas-per-source-is-over-limit': {
    message: 'createUpdateDBForm.validations.replicasPerSourceOverLimit',
    field: 'source'
  },
  'bdb-sources-per-replica-is-over-limit': {
    message: 'createUpdateDBForm.validations.sourcesPerReplicaOverLimit'
  },
  'bdb-source-and-replica-owner-mismatch': {
    message: 'createUpdateDBForm.validations.sourceAndReplicaOwnerMismatch',
    field: 'source'
  },
  [GENERAL_ERROR_CODE]: {
    message: 'generalError'
  },
  license_expired: {
    message: 'Cluster key has expired.',
    field: 'clusterKey'
  },
  license_invalid: {
    message: 'Cluster key is invalid.',
    field: 'clusterKey'
  },
  path_invalid: {
    message: "Can't access one of the set paths."
  },
  general_failure: {
    message: 'Sorry, general error occurred. Please contact support.'
  },
  sync_source_exist: {
    message: 'Redis address already used.',
    field: 'source'
  },
  email_server_settings: {
    message:
      'Error saving email server settings. Update the setting, verify the test email was received, and then try again to save the settings.'
  },
  new_password_cannot_be_same_as_current: {
    message: 'New password can not be same as current.',
    field: 'password'
  },
  error_new_password_complexity_low: {
    message:
      'Password must comply with the following rules:<br>Minimum 8 characters<br>at least 1 upper, 1 lower character<br>at least 1 number (not in first and last position)<br>1 special char (anywhere except first and last position)<br>Not contain a user ID or reverse of user ID<br>No sequence of 3 or more repeating numbers or char in a row',
    field: 'password'
  },
  // Average value size
  'bdb-rof-invalid-average-value-size': {
    message: 'createUpdateDBForm.validations.itemSizeInvalid',
    field: 'itemSize'
  },
  // SETTINGS - MAP AWS ZONES
  'account-map-aws-zones-general': {
    message: 'AccountSettingsScreen.serverErrors.accountMapAwsZonesGeneral'
  },
  // TEAM SETTINGS
  'user-already-invited-to-account': {
    message: 'accessManagement.tabs.teams.errors.userAlreadyInvitedToAccount',
    field: 'email'
  },
  'team-general-error': {
    message: 'accessManagement.tabs.teams.errors.teamsGeneralError'
  },
  'account-user-not-verified': {
    message: 'accessManagement.tabs.teams.errors.accountUserNotVerified'
  },
  // SAML CONFIG
  'saml-config-not-owned-by-account': {
    message: 'accessManagement.tabs.singleSignOn.errors.configNotOwnedByAccount'
  },
  'saml-config-not-found': {
    message: 'accessManagement.tabs.singleSignOn.errors.configNotFound'
  },
  'saml-config-invalid-id': {
    message: 'accessManagement.tabs.singleSignOn.errors.configInvalidId'
  },
  'saml-config-invalid-domain': {
    message: 'accessManagement.tabs.singleSignOn.errors.configInvalidDomain'
  },
  'saml-config-duplicate-domain': {
    message: 'accessManagement.tabs.singleSignOn.errors.configDuplicateDomain'
  },
  'saml-config-duplicate-domain-non-active': {
    message: 'accessManagement.tabs.singleSignOn.errors.configDuplicateDomainNonActive'
  },
  'saml-config-not-configured': {
    message: 'accessManagement.tabs.singleSignOn.errors.configNotConfigured'
  },
  'saml-config-not-active': {
    message: 'accessManagement.tabs.singleSignOn.errors.configNotActive'
  },
  'saml-config-domain-mismatch': {
    message: 'accessManagement.tabs.singleSignOn.errors.configDomainMismatch'
  },
  'saml-identity-provider-not-configured': {
    message: 'accessManagement.tabs.singleSignOn.errors.identityProviderNotConfigured'
  },
  'saml-identity-provider-not-found': {
    message: 'accessManagement.tabs.singleSignOn.errors.identityProviderNotFound'
  },
  'saml-config-not-all-accounts-have-local-owners': {
    message: 'accessManagement.tabs.singleSignOn.errors.samlConfigAccountHasNoOwner'
  },
  'saml-config-not-a-local-owner-in-account': {
    message: 'accessManagement.tabs.singleSignOn.errors.samlConfigNotALocalOwnerInAccount'
  },
  'saml-config-invalid-account-mapping': {
    message: 'accessManagement.tabs.singleSignOn.errors.samlConfigInvalidAccountMapping'
  },
  'saml-account-mapping-deprovision-with-no-owner': {
    message: 'accessManagement.tabs.singleSignOn.errors.samlAccountMappingDeprovisionNoOwner'
  },
  // Bdb info
  'bdb-is-not-active': {
    message: 'httpErrorsMap.bdbIsNotActive'
  },
  'bdb-is-deleted': {
    message: 'httpErrorsMap.bdbIsDeleted'
  },
  'cluster-communication-error': {
    message: 'httpErrorsMap.clusterCommunicationError'
  },
  'bdb-cannot-be-updated': {
    message: 'httpErrorsMap.bdbCannotBeUpdated'
  },
  // Bdb certificate
  'bdb-cert-missing-or-invalid': {
    message: 'httpErrorsMap.bdbCertMissingOrInvalid',
    field: 'certificate'
  },
  'bdb-cert-algorithm-not-supported': {
    message: 'httpErrorsMap.bdbCertAlgorithmNotSupported',
    field: 'certificate'
  },

  'bdb-cert-expired': {
    message: 'createUpdateDBForm.validations.certExpired',
    field: 'certificate'
  },
  // RCP
  'rcp-permissions-validation-failed': {
    message: 'createSubscription.pro.serverErrors.rcpPermissionsValidationFailed'
  },
  'cloud-account-permissions-validation-failed': {
    message: 'createSubscription.pro.serverErrors.cloudAccountPermissionsValidationFailed',
    field: 'cloud_account'
  },
  'rcp-aws-access-credentials': {
    message: 'createSubscription.pro.serverErrors.rcpAwsAccessCredentials'
  },
  'rcp-plan-general-error': {
    message: 'createSubscription.pro.serverErrors.rcpPlanGeneralError'
  },
  'rcp-illegal-status': {
    message: 'createSubscription.pro.serverErrors.rcpIllegalStatus'
  },
  'rcp-provision-general-error': {
    message: 'createSubscription.pro.serverErrors.rcpProvisionGeneralError'
  },
  'rcp-validate-provision-error': {
    message: 'createSubscription.pro.serverErrors.rcpValidateProvisionError'
  },
  'rcp-bdb-number-exceeded': {
    message: 'createSubscription.pro.serverErrors.rcpBdbNumberExceeded'
  },
  'rcp-bdb-number-is-empty': {
    message: 'createSubscription.pro.serverErrors.rcpBdbNumberIsEmpty'
  },
  'rcp-bdb-size-exceeded': {
    message: 'createSubscription.pro.serverErrors.rcpBdbSizeExceeded',
    field: 'memory_size_gb'
  },
  'rcp-bdb-cant-decrease-size': {
    message: 'createSubscription.pro.serverErrors.rcpBdbCantDecreaseSize',
    field: 'memory_size_gb'
  },
  'rcp-throughput-exceeded': {
    message: 'createSubscription.pro.serverErrors.rcpThroughputExceeded',
    field: 'max_throughput'
  },
  'rcp-destroy-error': {
    message: 'createSubscription.pro.serverErrors.rcpDestroyError'
  },
  'rcp-invalid-cidr': {
    message: 'createSubscription.pro.serverErrors.rcpInvalidCidr',
    field: 'cidr'
  },
  'rcp-invalid-rfc-cidr': {
    message: 'createSubscription.pro.serverErrors.rcpInvalidRfcCidr',
    field: 'cidr'
  },
  'rcp-aws-not-enough-azs': {
    message: 'createSubscription.pro.serverErrors.rcpAwsNotEnoughAzs'
  },
  'rcp-not-enough-azs': {
    message: 'createSubscription.pro.serverErrors.rcpNotEnoughAzs',
    field: 'multi_az'
  },
  'rcp-redis-version-is-not-supported': {
    message: 'createSubscription.pro.serverErrors.rcpRedisVersionIsNotSupported'
  },
  'rcp-rof-is-not-supported': {
    message: 'createSubscription.pro.serverErrors.rcpRofIsNotSupported'
  },
  'bdb-custom-regex-rules-with-oss': {
    message: 'createSubscription.pro.serverErrors.bdbCustomRegexRulesWithOss'
  },
  'bdb-oss-cluster-and-replica-of-not-supported': {
    message: 'createSubscription.pro.serverErrors.bdbOssClusterAndReplicaOfNotSupported'
  },
  'cluster-under-maintenance': {
    message: 'databaseConfigTab.crdb.errorMessages.clusterUnderMaintenance'
  },
  'rcp-no-valid-cluster-options-new': {
    message: 'httpErrorsMap.rcpNoValidClusterOptionsOrShardNew'
  },
  'rcp-cannot-place-shard-new': {
    message: 'httpErrorsMap.rcpNoValidClusterOptionsOrShardNew'
  },
  // ACL
  'redis-acl-name-already-exist': {
    message: 'accessControl.redisAclsTab.tableColumns.allReadyExistsErrorMessage',
    field: 'name'
  },
  'redis-acl-invalid-pubsub-pattern': {
    message: 'httpErrorsMap.redisAclInvalidPubsubPattern'
  },
  'redis-acl-allchannels-before-specific-channel': {
    message: 'httpErrorsMap.redisAclAllchannelsBeforeSpecificChannel'
  },
  'redis-acl-wrong-acl-rule-pattern': {
    message: 'httpErrorsMap.redisAclWrongAclRulePattern'
  },
  'redis-acl-account-not-support-pubsub-pattern': {
    message: 'httpErrorsMap.redisAclAccountNotSupportPubsubPattern'
  },
  'redis-acl-invalid-category-rule-pattern': {
    message: 'httpErrorsMap.redisAclInvalidCategoryRulePattern'
  },
  'redis-acl-allkeys-before-specific-key': {
    message: 'httpErrorsMap.redisAclAllkeysBeforeSpecificKey'
  },
  'redis-acl-invalid-keyspace-rule-pattern': {
    message: 'httpErrorsMap.redisAclInvalidKeySpaceRulePattern'
  },
  'redis-acl-invalid-command-or-module-command-rule-pattern': {
    message: 'httpErrorsMap.redisAclInvalidCommandOrModuleRulePattern'
  },
  'redis-acl-invalid-key-permission-pattern': {
    message: 'httpErrorsMap.redisAclInvalidKeyPermissionPattern'
  },
  'redis-acl-invalid-selectors-pattern': {
    message: 'httpErrorsMap.redisAclInvalidSelectorsPattern'
  },
  'bdb-is-not-in-correct-version-for-given-acl-version': {
    message: 'httpErrorsMap.redisAclVersionNotSupportedByBdbVersion'
  },
  'cluster-user-already-exists': {
    message: 'accessControl.usersTab.errors.nameAlreadyExists',
    field: 'name'
  },
  'cluster-user-default-is-reserved': {
    message: 'accessControl.usersTab.errors.nameDefault',
    field: 'name'
  },
  'cluster-role-name-already-exist': {
    message: 'accessControl.rolesTab.errors.nameAlreadyExits'
  },
  'cluster-role-name-wrong-length-range': {
    message: 'accessControl.rolesTab.errors.nameDoesNotExist'
  },
  'cluster-role-wrong-name-pattern': {
    message: 'accessControl.rolesTab.errors.wrongNamePattern'
  },
  'bdb-status-is-not-active': {
    message: 'accessControl.rolesTab.errors.dbStatusNotActive',
    field: 'databases'
  },
  'new-role-has-no-name': {
    message: 'accessControl.rolesTab.errors.nameDoesNotExist',
    field: 'name'
  },
  // payment
  'payment-info-cant-be-deleted': {
    message: 'miniCard.menu.errors.cannotDeletePayment'
  },
  'payment-info-edit-general': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoEditGeneral'
  },
  'payment-info-general-fail': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoGeneralFail'
  },
  'payment-authonly-invalid-card-number': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentAuthonlyInvalidCardNumber'
  },
  'payment-authonly-general-fail': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentAuthonlyGeneralFail'
  },
  'payment-info-invalid-card-number': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoInvalidCardNumber'
  },
  'payment-info-invalid-cvv': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoInvalidCvv',
    field: 'cvv'
  },
  'payment-info-missing-cvv': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoMissingCvv'
  },
  'payment-info-invalid-card-type': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoInvalidCardType'
  },
  'payment-info-card-expiration-month-is-invalid': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoCardExpirationIsInvalid'
  },
  'payment-info-card-expiration-year-is-invalid': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoCardExpirationIsInvalid'
  },
  'payment-info-card-expiration-date-is-invalid': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoCardExpirationIsInvalid'
  },
  'payment-info-us-address-is-invalid': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoUSAddressIsInvalid'
  },
  'payment-info-us-address-is-invalid-params': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoUSAddressIsInvalid'
  },
  'payment-info-existing-us-address-is-invalid': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoExistingUSAddressIsInvalid'
  },
  'payment-info-card-last-4-digits-is-invalid': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoCardLast4DigitsIsInvalid'
  },
  INSUFFICIENT_FUNDS: {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoInsufficientFunds'
  },
  FRAUD_DETECTED: {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoFraudDetected'
  },
  FRAUD_ERROR: {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoFraudError'
  },
  'update-subs-general-error': {
    message: 'updateSubsGeneralError'
  },
  'payment-info-zip-is-missing': {
    message: 'paymentInfoZIPIsMissing'
  },
  'payment-info-cczip-is-missing': {
    message: 'paymentInfoZIPIsMissing'
  },
  recursive_sync_source_not_allowed: {
    message: 'recursiveSyncSourceNotAllowed'
  },
  'payment-info-invalid-vatid': {
    message: 'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoInvalidVatid'
  },
  'payment-info-unsupported-card-type': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoUnsupportedCardType'
  },
  'payment-info-not-credit-card-sub-type': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoNotCreditCardSubType'
  },
  'payment-info-not-credit-card-category': {
    message:
      'billingAndPayments.tabs.payments.addEditCCDialog.errors.paymentInfoNotCreditCardCategory'
  },
  // add vpc peering
  'vpc-peering-already-exist': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringAlreadyExist'
  },
  'vpc-peering-general-error': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringGeneralError'
  },
  'vpc-peering-invalid-aws-account-uid': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringInvalidAwsAccountUid',
    field: 'awsAccount'
  },
  'vpc-peering-invalid-vpc-uid': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringInvalidUpcUid',
    field: 'awsVpcId'
  },
  'vpc-peering-invalid-vpc-cidr': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringInvalidVpcCidr',
    field: 'awsVpcCIDR'
  },
  'vpc-peering-cidr-already-exist': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringCidrAlreadyExist',
    field: 'awsVpcCIDR'
  },
  'vpc-peering-cidr-overlap': {
    message: 'vpcPeering.addPeering.errors.vpcPeeringCidrOverlap',
    field: 'awsVpcCIDR'
  },
  // Whitelist - Allow list
  'rcp-whitelist-invalid-sg': {
    message: 'invalidSg'
  },
  'rcp-whitelist-general-error': {
    message: 'whiteListGeneralError'
  },
  'rcp-whitelist-invalid-cidrs': {
    message: 'invalidCidr'
  },
  'rcp-whitelist-cidrs-limit-exceeded': {
    message: 'cidrLimit'
  },
  'rcp-whitelist-sg-limit-exceeded': {
    message: 'sgLimit'
  },
  'rcp-whitelist-duplicate-cidrs': {
    message: 'duplicateCidr'
  },
  'rcp-whitelist-duplicate-sg': {
    message: 'duplicateSg'
  },
  'rcp-whitelist-invalid-cidr-format': {
    message: 'invalidCidrFormat'
  },
  'rcp-whitelist-invalid-sg-format': {
    message: 'invalidSgFormat'
  },
  // Subscription
  'subscription-is-deleted': {
    message: 'httpErrorsMap.subscriptionIsDeleted'
  },
  // RCP Reserved
  'subscription-invalid-activate-key': {
    message: 'Invalid activation key, please contact support@redislabs.com',
    field: 'activation_key'
  },
  'subscription-activation-key-expired': {
    message: 'Expired activation key, please contact support@redislabs.com',
    field: 'activation_key'
  },
  'subscription-activation-general-error': {
    message: 'General Error, please contact support@redislabs.com'
  },
  'subscription-blank-activation-key': {
    message: 'Activation Key cannot be blank',
    field: 'activation_key'
  },
  // User Profile related errors
  'user-profile-general': {
    message: 'userProfile.serverMessages.userProfileGeneral'
  },
  'user-password-is-wrong': {
    message: 'userProfile.serverMessages.userPassWrong'
  },
  'user-new-passwords-dont-match': {
    message: 'userProfile.serverMessages.userPassNoMatch'
  },
  'user-password-previously-used': {
    message: 'userProfile.serverMessages.passWasUsed'
  },
  'user-full-name-is-illegal': {
    message: 'userProfile.serverMessages.illegalName',
    field: 'name'
  },
  // Activate invitation related errors
  activate_invitation_general: {
    message: 'failed to activate account.'
  },
  'user-invalid-activate-invitation-key': {
    message: 'User account activation code is invalid.'
  },
  'user-password-is-illegal': {
    message:
      'Password must be 8 to 128 characters long, including a combination of lower and uppercase letters, numbers and special characters.',
    field: 'password'
  },
  'user-email-exists': {
    message: 'Sorry, this email has already been taken.',
    field: 'email'
  },
  'user-invalid-email': {
    message: 'The email address is not valid.',
    field: 'email'
  },
  'user-email-blacklisted': {
    message: i18translation.httpErrorsMap.userEmailBlacklisted
  },
  'account-company-exists': {
    message: 'Trying to register with existing company.',
    field: 'company'
  },
  error_general: {
    message: 'Sorry, general error occurred. Please contact support.'
  },
  // Cloud Account
  'cloud-account-invalid-credentials': {
    message: 'cloudAccounts.serverMessages.invalidCredentials',
    field: ['access_key', 'secret_key']
  },
  'cloud-account-name-already-exists': {
    message: 'cloudAccounts.serverMessages.cloudAccountNameAlreadyExist'
  },
  'cloud-account-general-save-error': {
    message: 'cloudAccounts.serverMessages.cloudAccountGeneralSaveError'
  },
  'cloud-account-creds-in-wrong-account': {
    message: 'cloudAccounts.serverMessages.cloudAccountCredsInWrongAccount',
    field: ['access_key', 'secret_key']
  },
  'cloud-account-provider-access-key-already-exists': {
    message: 'cloudAccounts.serverMessages.cloudAccountProviderAccessKeyAlreadyExists',
    field: ['access_key', 'secret_key']
  },
  'rcp-module-plan-clustering-error': {
    message: 'cloudAccounts.serverMessages.rcpModulePlanClusteringError'
  },
  'rcp-module-plan-resharding-error': {
    message: 'cloudAccounts.serverMessages.rcpModulePlanReshardingError'
  },
  'rcp-shard-count-does-not-fit-the-required-operation': {
    message: 'cloudAccounts.serverMessages.rcpShardCountDoesNotFitTheRequiredOperation'
  },
  'cloud-account-does-not-exists': {
    message: 'cloudAccounts.serverMessages.cloudAccountDoesNotExist'
  },
  secret_key_name_illegal: {
    message: 'accessManagement.tabs.apiKeys.table.columns.actions.errors.secret_key_name_illegal'
  },
  account_api_key_already_exists: {
    message: 'accessManagement.tabs.apiKeys.errors.account_api_key_already_exists'
  },
  secret_key_already_exists: {
    message: 'accessManagement.tabs.apiKeys.errors.secret_key_already_exists'
  },
  // AA
  'crdb-is-not-active': {
    message: 'httpErrorsMap.crdbIsNotActive'
  },
  'aa-change-request-error': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'change-request-changes-does-not-exists': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'change-request-crdbs-to-create-is-missing': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'change-request-invalid-execution-state': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'change-request-remove-region-crdb-to-update-not-allowed': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-new-instance-count-does-not-match-new-regions': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'region-does-not-exist': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'change-request-general-error': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'change-request-does-not-exist': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-missing-db-configuration': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-instances-are-missing': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-bdbs-are-missing': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-less-than-one-quantity-not-allowed': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'existing-crdb-instances-are-missing': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'task-id-does-not-exist': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'bdb-does-not-belong-to-crdb': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'bdb-does-not-belong-to-region': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-region-is-missing': {
    message: 'databaseConfigTab.crdb.errorMessages.aaChangeRequestGeneralError'
  },
  'crdb-flush-failed': {
    message: 'databaseConfigTab.crdb.errorMessages.aaFlushTaskFailed'
  },
  'aa-flush-task-failed': {
    message: 'databaseConfigTab.crdb.errorMessages.aaFlushTaskFailed'
  },
  'ssl-field-should-be-enabled': {
    message: 'databaseConfigTab.crdb.errorMessages.sslFieldShouldBeEnabled'
  },
  'add-region-and-update-crdb-not-allowed-parallel': {
    message: 'databaseConfigTab.crdb.errorMessages.addRegionAndUpdateCrdbNotAllowedParallel'
  },
  'remove-region-cluster-not-found': {
    message: 'databaseConfigTab.crdb.errorMessages.removedRegionClusterNotFound'
  },
  'active-active-regions-minimum-quantity': {
    message: 'databaseConfigTab.crdb.errorMessages.activeActiveRegionsMinimumQuantity'
  },
  'account-does-not-support-active-active-configuration': {
    message: 'databaseConfigTab.crdb.errorMessages.accountDoesNotSupportActiveActiveConfiguration'
  },
  'change-request-more-than-one-crdb-change-type-not-allowed': {
    message: 'databaseConfigTab.crdb.errorMessages.changeRequestMoreThanOneCrdbChangeTypeNotAllowed'
  },
  'change-request-subscription-is-not-active': {
    message: 'databaseConfigTab.crdb.errorMessages.changeRequestSubscriptionIsNotActive'
  },
  'change-request-crdb-is-not-active': {
    message: 'databaseConfigTab.crdb.errorMessages.changeRequestCrdbIsNotActive'
  },
  'crdb-invalid-name-format': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbInvalidNameFormat',
    field: getCrdbFieldPath('name')
  },
  'crdb-name-already-exist': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbNameAlreadyExists',
    field: getCrdbFieldPath('name')
  },
  'crdb-maximum-quantity-exceeded': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbMaximumQuantityExceeded'
  },
  'crdb-sharding-must-be-enabled': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbShardingMustBeEnabled'
  },
  'crdb-invalid-memory-size': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbInvalidMemorySize'
  },
  'crdb-invalid-throughput': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbInvalidThroughput'
  },
  'crdb-not-owned-by-account': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbNotOwnedByAccount'
  },
  'crdb-does-not-exist': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbDoesNotExist'
  },
  'crdb-does-not-belong-to-subscription': {
    message: 'databaseConfigTab.crdb.errorMessages.crdbDoesNotBelongToSubscription'
  },
  'crdb-invalid-cidr-wrong-format': {
    message: 'databaseConfigTab.crdb.errorMessages.invalidCIDR'
  },
  'crdb-invalid-cidr-not-rfc1918': {
    message: 'databaseConfigTab.crdb.errorMessages.invalidCIDR'
  },
  'crdb-invalid-cidr-overlapping-other-regions': {
    message: 'databaseConfigTab.crdb.errorMessages.CIDROverlappingOtherRegions'
  },
  'crdb-certificate-invalid': {
    message: 'httpErrorsMap.crdbCertificateInvalid'
  },
  'crdb-certificate-expired': {
    message: 'httpErrorsMap.crdbCertificateExpired'
  },
  'crdb-certificate-missing': {
    message: 'httpErrorsMap.crdbCertificateMissing'
  },

  // MFA
  'mfa-invalid-code': {
    message: 'mfa.sms.modal.errors.mfaInvalidCode',
    field: ['verificationCode']
  },
  'user-mfa-general-error': {
    message: 'mfa.sms.modal.errors.mfaInvalidCode'
  },
  // GENERAL
  'insufficient-permission': {
    message: 'The current user does not have the required permissions to complete this request.'
  },
  'insufficient-permission-with-roles': {
    message:
      'The current user does not have the required permissions to complete this request. Only users with {{allowed-roles}} can perform it.'
  },
  // AWS Account
  'marketplace-account-already-bound-to-another-product': {
    message: 'awsMarketPlace.messages.alreadyBoundToProduct'
  },
  ultimate_request: {
    message: 'Could not send request for Ultimate subscription. Please try again later.'
  },
  'error-test': {
    message: 'test-error',
    field: 'backupPath'
  },
  'no-account-associated-for-current-authentication-mode': {
    message: 'LoginScreen.errors.noAccountsForChosenSignIn'
  },
  'authentication-failed': {
    message: 'LoginScreen.errors.authenticationFailed'
  },
  'port-already-in-use': {
    message: 'databaseConfigTab.portSelection.portInput.errors.portInUse'
  },
  // PSC
  'psc-endpoint-already-exist-with-same-name-on-same-project': {
    message:
      'subscriptionDetailsTabs.connectivity.psc.validationMessages.pscEndpointAlreadyExistWithSameNameOnSameProject',
    field: 'psc_endpoint_details'
  },
  'redis-acl-not-owned-by-account': {
    message: i18translation.accessControl.rolesTab.roleManager.errors.redisAclNotOwnedByAccount,
    field: 'bdb_acl_bindings'
  }
});

export type ErrorsMapCodes = keyof typeof errorsMap;
