import { showToast } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { accountsQueries } from 'queryClient/accounts/accounts.queries';
import usePermissionValidation from '../components/PermissionsProtectors/hooks/usePermissionValidation';

export const useSupportPage = () => {
  const { data, isInitialLoading } = accountsQueries.useZendeskLink();
  const hasHelpDeskPermission = usePermissionValidation('account_call_helpdesk');
  const { t } = useTranslation();
  const keyPrefix = i18translation.support;

  const openSupportPage = () => {
    if (hasHelpDeskPermission) {
      if (data?.url) {
        window.open(data?.url, '_blank');

        return { url: data?.url };
      }
    } else {
      showToast(t(keyPrefix.errorMessage), 'error');
    }

    return { url: null };
  };

  return { openSupportPage, isInitialLoading };
};
