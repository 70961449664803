import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { newRedisOptInUtils } from 'utils/newRedisOptIn/newRedisOptIn.utils';
import * as S from './RenderRegionItem.style';
import { NewRedisOptInProps } from '../../CreationRegionSelect.types';

interface RenderRegionItemProps
  extends Pick<
    NewRedisOptInProps,
    'isSupportingNewRedisOptIn' | 'newRedisOptInRegionIds' | 'redisOptInVersion'
  > {
  region: CloudRegion;
}

const RenderRegionItem = ({
  region,
  isSupportingNewRedisOptIn,
  newRedisOptInRegionIds,
  redisOptInVersion
}: RenderRegionItemProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.newRedisOptIn;

  const newRedisRegions = newRedisOptInUtils.parseRegionIds(newRedisOptInRegionIds);
  const isRegionNewRedis = (regionId: number) => newRedisRegions.includes(regionId);

  return (
    <S.Container>
      <S.RegionDisplayField region={region?.name} />
      {isSupportingNewRedisOptIn && isRegionNewRedis(region.region_id) && (
        <S.Redis7Text>
          {`(${t(keyPrefix.regionItemNewRedisText, { version: redisOptInVersion })})`}
        </S.Redis7Text>
      )}
    </S.Container>
  );
};

export default RenderRegionItem;
