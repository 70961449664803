import styled from 'styled-components/macro';
import { newTheme } from '@redislabsdev/redis-ui-styles';
import { CheckBoldIcon } from '@redislabsdev/redis-ui-icons';
import { RedisStackIcon } from '@redislabsdev/redis-ui-icons/multicolor';

export const Banner = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: #f5f8ff;
  padding: 1.6rem 2.4rem;
`;

export const BannerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BannerLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const BannerRightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-left: 6.4rem;
`;

export const BannerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${newTheme.semantic.color.background.neutral400};
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const CheckIcon = styled(CheckBoldIcon)`
  margin-right: 0.8rem;
  & > path {
    fill: ${newTheme.semantic.color.text.success500};
  }
`;

export const StackIcon = styled(RedisStackIcon)`
  margin-left: 0.4rem;
  margin-right: 0.4rem;
`;

export const Spacer = styled.div`
  margin-left: 0.4rem;
`;
