import * as S from '../Onboarding.style';

const getRoleStickerKey = (role: string) => {
  const map: { [k: string]: S.Role } = {
    architect: 'roleArchitect',
    'business-analyst': 'roleBusinessAnalystDataScientist',
    'business-executive': 'roleBusinessExecutive',
    'data-scientist': 'roleBusinessAnalystDataScientist',
    'data-digital-innovation-executive': 'roleDigitalInnovationExecutive',
    dba: 'roleDbaDevOpsTo',
    'devops-engineer': 'roleDbaDevOpsTo',
    'it-executive': 'roleItExecutive',
    'director-of-development': 'roleManagerOfDevelopmentSoftwareDeveloper',
    'product-leader': 'roleProductLeaderProjectProductManager',
    pm: 'roleProductLeaderProjectProductManager',
    'software-developer': 'roleManagerOfDevelopmentSoftwareDeveloper',
    student: 'roleStuden',
    other: 'roleOther'
  };

  return map[role];
};

const getClientStickerKey = (client: string) => {
  const map: { [k: string]: S.Client } = {
    nodejs: 'clientNodejs',
    java: 'clientJava',
    python: 'clientPython',
    'dot-net': 'clientNet',
    other: 'clientOther'
  };

  return map[client];
};

export const stickersUtils = {
  getRoleStickerKey,
  getClientStickerKey
};
