import { newRedisOptInUtils } from 'utils/newRedisOptIn/newRedisOptIn.utils';
import { RedisOptInVersion } from 'screens/CreateSubscription/components/CloudAndRegionSelection/components/CreationRegionSelect/CreationRegionSelect.types';
import { useState } from 'react';
import { FreeDbCloudVendorProps } from 'screens/NewCustomer/FreeDb/FreeDb.types';
import {
  getVendorRegionPlanMapper,
  getFreePlans,
  getFirstRegion,
  DEFAULT_VENDOR
} from 'screens/NewCustomer/FreeDb/utils/loginRelated';
import { CloudProviders } from 'components/CloudProvider/CloudProvider.types';
import useRegions from '../../../../../../hooks/useRegions';
import { useEssentialPlansQuery } from '../../../../../../hooks/useEssentialPlansQuery/useEssentialPlansQuery';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';

const useNewRedisOptInCloudRegionSelectionProps = (): FreeDbCloudVendorProps => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const { getRegionByRegionName, getRegionsByProvider } = useRegions();
  const { data: plans } = useEssentialPlansQuery({ refetchOnMount: false });
  const [isOptedToNewRedis, setIsOptedToNewRedis] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<CloudProviders>(DEFAULT_VENDOR);

  const isSupportingRedis7OptIn = newRedisOptInUtils.getIsSupportingNewRedis(
    systemParams.redis_7_region_ids
  );
  const isSupportingRedis72OptIn = newRedisOptInUtils.getIsSupportingNewRedis(
    systemParams.redis_72_region_ids
  );

  let redisOptInVersion: RedisOptInVersion = null;
  if (isSupportingRedis72OptIn) {
    redisOptInVersion = '7.2';
  } else if (isSupportingRedis7OptIn) {
    redisOptInVersion = '7.0';
  }

  const newRedisOptInRegionIds =
    redisOptInVersion === '7.0'
      ? systemParams.redis_7_region_ids
      : systemParams.redis_72_region_ids;

  const freePlans = getFreePlans({
    plans,
    isOptedToNewRedis,
    newRedisRegions: newRedisOptInUtils.parseRegionIds(newRedisOptInRegionIds)
  });

  const vendorRegionPlanMapper = getVendorRegionPlanMapper(freePlans);

  const [selectedRegion, setSelectedRegion] = useState<string>(() =>
    getFirstRegion(vendorRegionPlanMapper[DEFAULT_VENDOR], getRegionByRegionName)
  );

  const onNewRedisOptInClick = (isOptingIn: boolean) => {
    if (isOptingIn) {
      const firstNewRedisRegion = getRegionsByProvider(selectedVendor).find((region) =>
        newRedisOptInUtils.parseRegionIds(newRedisOptInRegionIds).includes(Number(region.id))
      );

      if (firstNewRedisRegion) {
        setSelectedRegion(firstNewRedisRegion.name);
      }
    } else {
      const allAvailableFreePlans = getVendorRegionPlanMapper(
        getFreePlans({
          plans
        })
      );
      setSelectedRegion(
        getFirstRegion(allAvailableFreePlans[selectedVendor], getRegionByRegionName)
      );
    }
    setIsOptedToNewRedis(isOptingIn);
  };

  return {
    onNewRedisOptInClick,
    isSupportingNewRedisOptIn: isSupportingRedis7OptIn || isSupportingRedis72OptIn,
    isOptedToNewRedis,
    redisOptInVersion,
    newRedisOptInRegionIds,
    selectedRegion,
    setSelectedRegion,
    selectedVendor,
    setSelectedVendor,
    vendorRegionPlanMapper,
    freePlans
  };
};

export default useNewRedisOptInCloudRegionSelectionProps;
