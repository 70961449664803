import { useContext } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components/macro';

const useTheme = (): DefaultTheme => {
  const theme = useContext(ThemeContext);

  // @ts-ignore
  return theme || {};
};

export default useTheme;
