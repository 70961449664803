import styled from 'styled-components/macro';
import { ScrollContainer } from '../../../../../utils/style';

export const Container = styled(ScrollContainer)`
  display: flex;
  flex-flow: column;

  & div {
    p {
      display: block;
      padding-top: 1.8rem;
    }

    button {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
  }
`;

export const InlineInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 40rem;
`;

export const InlineTerms = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 0.6rem;
  color: ${({ theme }) => theme.colors.blue2};

  & > a {
    color: ${({ theme }) => theme.colors.blue6};
    padding-top: 0;
    padding-left: 4px;
    padding-right: 4px;
    text-decoration: none;
    font-weight: bold;
  }
`;
