import { Route, Switch } from 'react-router-dom';
import { routes } from 'utils/constants/routes';
import { parametersQueries } from 'queryClient/parameters/parameters.queries';
import FreeDb from './FreeDb/FreeDb';
import Onboarding from './Onboarding/Onboarding';
import OnboardingMFE from './Onboarding/OnboardingMFE';

const NewCustomer = () => {
  const { systemParams } = parametersQueries.useSystemParameters();

  const OnboardingComponent = systemParams.external_feature_flag_onboarding_mfe_enabled
    ? OnboardingMFE
    : Onboarding;

  return (
    <Switch>
      <Route exact path={routes.newCustomer.onboarding} component={OnboardingComponent} />
      <Route exact path={routes.newCustomer.freeDb} component={FreeDb} />
    </Switch>
  );
};

export default NewCustomer;
